import React, { useState } from "react";
import {
    Col,
    Form,
    Row,
    Card,
    Tooltip,
    OverlayTrigger,
} from "react-bootstrap";
import {
    faExclamationCircle
} from "@fortawesome/free-solid-svg-icons";
import {
    HelpTooltip
} from "../../assets/functions.js";
import DropzoneComponent from "../Dropzone.js";
import { domainConfig } from "../../config.js";
import PopoverPicker from "../PopoverPicker.js";

const QA = ({
    fonts,
    handleChange,
    values,
    setFieldValue,
    errors,
    touched,
    handlePreviewSuccess,
    handlePreviewError,
}) => {
    const [props, setProps] = useState({
        backgroundColor: values.json.apresentation?.textSide?.backgroundColor,
        textColor: values.json.apresentation?.textSide?.textColor
    });

    return (
        <>
            <Form.Group as={Col} className="textHeaderAplicativo mb-3">
                <h5 className="card-title mb-0">Definições</h5>
            </Form.Group>

            <Row className="mx-0 mb-3">
                <Form.Group
                    className="mb-3"
                    as={Col}
                    sm="12"
                   
                >
                    <Form.Label>Título</Form.Label>
                    <Form.Control
                        type="text"
                        name="titulo"
                        value={values.titulo}
                        onChange={handleChange}
                        isInvalid={!!errors.titulo}
                        isValid={touched.titulo && !errors.titulo}
                    />
                    <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        {errors.titulo}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                    className="mb-3"
                    as={Col}
                    sm="12"
                   
                >
                    <Form.Check
                        className="w-auto"
                        checked={values.json.autoApproval ? true : false}
                        type="checkbox"
                        name="json.autoApproval"
                        label="Aprovar mensagens automaticamente"
                        onChange={(e) => {
                            setFieldValue(
                                "json.autoApproval",
                                values.json.autoApproval ? false : true
                            );
                        }}
                    />
                </Form.Group>
                <p className="text-start fw-bold mb-2 mt-3">Mensagens</p>
                <Form.Group
                    as={Col}
                    md={4}
                    className="mb-3"
                   >
                    <Form.Label>Máximo de caracteres</Form.Label>
                    <Form.Control
                        type="number"
                        name="json.maxlength"
                        step={"10"}
                        value={values.json.maxlength}
                        onChange={handleChange}
                        isInvalid={!!errors.json?.maxlength}
                        isValid={touched.json?.maxlength && !errors.json?.maxlength}
                    />
                    <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        {errors.json?.maxlength}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                    as={Col}
                    md={4}
                    className="mb-3"
                   >
                    <Form.Label>Mínimo de caracteres</Form.Label>
                    <Form.Control
                        type="number"
                        name="json.minlength"
                        step={"10"}
                        value={values.json.minlength}
                        onChange={handleChange}
                        isInvalid={!!errors.json?.minlength}
                        isValid={touched.json?.minlength && !errors.json?.minlength}
                    />
                    <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        {errors.json?.minlength}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                    as={Col}
                    md={4}
                    className="mb-3"
                   >
                    <Form.Label>Limite por participante</Form.Label>
                    <Form.Control
                        type="number"
                        name="json.messageLimit"
                        step={"1"}
                        value={values.json.messageLimit}
                        onChange={handleChange}
                        isInvalid={!!errors.json?.messageLimit}
                        isValid={touched.json?.messageLimit && !errors.json?.messageLimit}
                    />
                    <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        {errors.json?.messageLimit}
                    </Form.Control.Feedback>
                </Form.Group>
            </Row>

            <Form.Group as={Col} className="textHeaderAplicativo mb-3">
                <h5 className="card-title mb-0">
                    Participante
                </h5>
            </Form.Group>
            <Row className="mx-0 mb-3">
                <p className="text-start fw-bold mb-2">Tela inicial</p>
                <Form.Group
                    className="mb-3"
                    as={Col}
                    sm="12"
                   
                >
                    <Form.Label>Título</Form.Label>
                    <Form.Control
                        as="textarea"
                        name="json.title.value"
                        value={values.json.title.value}
                        onChange={(e) => {
                            if (e.target.value === '') {
                                setFieldValue("json.title.status", false);
                            } else {
                                setFieldValue("json.title.status", true);
                            }
                            setFieldValue("json.title.value", e.target.value);
                        }}
                        isInvalid={!!errors?.json?.title?.value}
                        isValid={touched?.json?.title?.value && !errors.json?.title?.value}
                    />
                    <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        {errors?.json?.title?.value}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                    className="mb-3"
                    as={Col}
                    sm="12"
                   
                >
                    <Form.Label>Descrição</Form.Label>
                    <Form.Control
                        as="textarea"
                        name="json.description.value"
                        value={values.json.description.value}
                        onChange={(e) => {
                            if (e.target.value === '') {
                                setFieldValue("json.description.status", false);
                            } else {
                                setFieldValue("json.description.status", true);
                            }
                            setFieldValue("json.description.value", e.target.value);
                        }}
                        isInvalid={!!errors?.json?.description?.value}
                        isValid={touched?.json?.description?.value && !errors.json?.description?.value}
                    />
                    <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        {errors?.json?.description?.value}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                    className="mb-3"
                    as={Col}
                    sm="12"
                   
                >
                    <Form.Check
                        className="w-auto"
                        checked={values.json.showMessageHistory ? true : false}
                        type="checkbox"
                        name="json.showMessageHistory"
                        label="Participante pode ver histórico de mensagens"
                        onChange={(e) => {
                            setFieldValue(
                                "json.showMessageHistory",
                                values.json.showMessageHistory ? false : true
                            );
                        }}
                    />
                    <Form.Check
                        className="w-auto"
                        checked={values.json.deleteHistory ? true : false}
                        type="checkbox"
                        name="json.deleteHistory"
                        label="Habilitar exclusão de mensagem pelo usuário"
                        onChange={(e) => {
                            setFieldValue(
                                "json.deleteHistory",
                                values.json.deleteHistory ? false : true
                            );
                        }}
                    />
                    <Form.Check
                        className="w-auto"
                        checked={values.json.userFeedback ? true : false}
                        type="checkbox"
                        name="json.userFeedback"
                        label="Mostrar status da mensagem para o participante"
                        onChange={(e) => {
                            setFieldValue(
                                "json.userFeedback",
                                values.json.userFeedback ? false : true
                            );
                        }}
                    />
                </Form.Group>
            </Row>
            <Form.Group as={Col} className="textHeaderAplicativo mb-3">
                <h5 className="card-title mb-0">Modo apresentação</h5>
            </Form.Group>
            <Row className="mx-0">
                <Form.Group
                    className="mb-3"
                    as={Col}
                    sm="12"
                   
                >
                    <Form.Label>Título</Form.Label>
                    <Form.Control
                        as="textarea"
                        name="json.apresentation.textSide.title.value"
                        value={values.json.apresentation?.textSide?.title?.value}
                        onChange={(e) => {
                            if (e.target.value === '') {
                                setFieldValue("json.apresentation.textSide.title.status", false);
                            } else {
                                setFieldValue("json.apresentation.textSide.title.status", true);
                            }
                            setFieldValue("json.apresentation.textSide.title.value", e.target.value);
                        }}
                        isInvalid={!!errors?.json?.apresentation?.textSide?.title?.value}
                        isValid={touched?.json?.apresentation?.textSide?.title?.value && !errors?.json?.apresentation?.textSide?.title?.value}
                    />
                    <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        {errors.json?.apresentation?.textSide?.title?.value}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                    as={Col}
                    md={3}
                    className="mb-3"
                   >
                    <Form.Label>Tamanho fonte (G) (rem)</Form.Label>
                    <Form.Control
                        type="number"
                        name="json.apresentation.textSide.messageSlider"
                        step={"0.01"}
                        value={values.json.apresentation?.textSide?.messageSlider}
                        onChange={handleChange}
                        isInvalid={!!errors.json?.apresentation?.textSide?.messageSlider}
                        isValid={touched.json?.apresentation?.textSide?.messageSlider && !errors.json?.apresentation?.textSide?.messageSlider}
                    />
                    <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        {errors.json?.apresentation?.textSide?.messageSlider}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                    as={Col}
                    md={3}
                    className="mb-3"
                   >
                    <Form.Label>Tamanho fonte (M) (rem)</Form.Label>
                    <Form.Control
                        type="number"
                        name="json.apresentation.textSide.h5"
                        step={"0.01"}
                        value={values.json.apresentation?.textSide?.h5}
                        onChange={handleChange}
                        isInvalid={!!errors.json?.apresentation?.textSide?.h5}
                        isValid={touched.json?.apresentation?.textSide?.h5 && !errors.json?.apresentation?.textSide?.h5}
                    />
                    <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        {errors.json?.apresentation?.textSide?.h5}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                    as={Col}
                    md={3}
                    className="mb-3"
                   >
                    <Form.Label>Tamanho fonte (P) (rem)</Form.Label>
                    <Form.Control
                        type="number"
                        name="json.apresentation.textSide.p"
                        step={"0.01"}
                        value={values.json.apresentation?.textSide?.p}
                        onChange={handleChange}
                        isInvalid={!!errors.json?.apresentation?.textSide?.p}
                        isValid={touched.json?.apresentation?.textSide?.p && !errors.json?.apresentation?.textSide?.p}
                    />
                    <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        {errors.json?.apresentation?.textSide?.p}
                    </Form.Control.Feedback>
                </Form.Group>
                <div className="col-md-3 mb-3">
                    <Form.Label>Cor de fundo e texto</Form.Label>
                    <div className={`d-flex justify-content-start align-items-center ${errors?.json?.apresentation?.textSide?.textColor || errors?.json?.apresentation?.textSide?.backgroundColor ? 'is-invalid' : ''}`}>
                        <Form.Control
                            type="hidden"
                            name="json.apresentation.textSide.backgroundColor"
                            value={values.json?.apresentation?.textSide?.backgroundColor}
                            isInvalid={!!errors?.json?.apresentation?.textSide?.backgroundColor}
                            isValid={touched?.json?.apresentation?.textSide?.backgroundColor && !errors?.json?.apresentation?.textSide?.backgroundColor}
                        />
                        <Form.Control
                            type="hidden"
                            name="json.apresentation.textSide.textColor"
                            value={values.json?.apresentation?.textSide?.textColor}
                            isInvalid={!!errors?.json?.apresentation?.textSide?.textColor}
                            isValid={touched?.json?.apresentation?.textSide?.textColor && !errors?.json?.apresentation?.textSide?.textColor}
                        />
                        <PopoverPicker
                            color={props.backgroundColor}
                            onChangeX={(e) => {
                                setFieldValue("json.apresentation.textSide.backgroundColor", e);
                                setProps(prevState => ({
                                    ...prevState,
                                    backgroundColor: e
                                }));
                            }}
                            classes={`me-1 picker ${errors?.json?.apresentation?.textSide?.backgroundColor ? 'is-invalid' : ''}`}
                        />
                        <PopoverPicker
                            color={props.textColor}
                            onChangeX={(e) => {
                                setFieldValue("json.apresentation.textSide.textColor", e);
                                setProps(prevState => ({
                                    ...prevState,
                                    textColor: e
                                }));
                            }}
                            classes={`me-1 picker ${errors?.json?.apresentation?.textSide?.textColor ? 'is-invalid' : ''}`}
                        />
                    </div>
                    <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        {errors?.json?.apresentation?.textSide?.textColor ? errors?.json?.apresentation?.textSide?.textColor : errors?.json?.apresentation?.textSide?.backgroundColor ? errors?.json?.apresentation?.textSide?.backgroundColor : ''}
                    </Form.Control.Feedback>
                </div>
                <Form.Group
                    as={Col}
                    md={3}
                    className="mb-3"
                   >
                    <Form.Label>Estilo de fonte</Form.Label>
                    <Form.Select
                        name="json.apresentation.textSide.fontFamily"
                        value={values.json.apresentation.textSide.fontFamily}
                        onChange={(e) => {
                            setFieldValue("json.apresentation.textSide.fontFamily", e.target.value);
                        }}
                    >
                        {fonts.map((element, index) => {
                            return (
                                <option
                                    style={{ fontFamily: element.font }}
                                    key={"font" + index}
                                    value={element.font}
                                >
                                    {element.font}
                                </option>
                            );
                        })}
                    </Form.Select>
                </Form.Group>
            </Row>
            <Row className="mx-0 mb-3">
                <Form.Group
                    as={Col}
                    md="6"
                   
                >
                    <Form.Label>Tipo de layout</Form.Label>
                    <Form.Select
                        onChange={(e) => {
                            if (e.target.value == 2) {
                                setFieldValue(
                                    "json.apresentation.imageSide.value", 40
                                );
                                setFieldValue(
                                    "json.apresentation.textSide.value", 60
                                );
                            } else {
                                setFieldValue(
                                    "json.apresentation.imageSide.value", 0
                                );
                                setFieldValue(
                                    "json.apresentation.textSide.value", 100
                                );
                            }
                        }}
                        value={values.json.apresentation?.imageSide?.value && values.json.apresentation?.imageSide?.value > 0 ? 2 : 1}>
                        <option value={1}>
                            Simples
                        </option>
                        <option value={2}>
                            Com banner
                        </option>
                    </Form.Select>
                </Form.Group>
            </Row>
            {
                values.json.apresentation.imageSide?.value > 0 &&
                <>
                    <Row className="mx-0">
                        <Form.Group
                            as={Col}
                            md="3"
                            className="mb-3"
                           >
                            <Form.Label>Tamanho do banner (%)</Form.Label>
                            <Form.Control
                                type="number"
                                name="json.apresentation.imageSide.value"
                                value={values.json.apresentation?.imageSide?.value}
                                onChange={(e) => {
                                    if (e.target.value === undefined || e.target.value === '') {
                                        e.target.value = 0
                                    }
                                    let textSideValue = 100 - e.target.value
                                    setFieldValue("json.apresentation.imageSide.value", e.target.value);
                                    setFieldValue("json.apresentation.textSide.value", textSideValue);
                                }}
                                isInvalid={!!errors.json?.apresentation?.imageSide?.value}
                                isValid={touched.json?.apresentation?.imageSide?.value && !errors.json?.apresentation?.imageSide?.value}
                            />
                            <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid">
                                {errors.json?.apresentation?.imageSide?.value}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group
                            as={Col}
                            md="3"
                            className="mb-3"
                           
                        >
                            <Form.Label>Posição</Form.Label>
                            <Form.Select
                                onChange={handleChange}
                                name="json.apresentation.imageSide.position"
                                value={values.json.apresentation?.imageSide?.position}>
                                <option value={'start'}>
                                    Esquerda
                                </option>
                                <option value={'end'}>
                                    Direita
                                </option>
                            </Form.Select>
                        </Form.Group>
                    </Row>
                    <Row className="mx-0 mb-3">
                        <Form.Group
                            as={Col}
                            md="6">
                            <Card className="mb-3">
                                <Card.Body>
                                    <div className="text-center mb-3">
                                        <div className="d-flex justify-content-center mb-3">
                                            <Form.Label>Banner</Form.Label>
                                        </div>
                                    </div>
                                    <Form.Control
                                        type="hidden"
                                        name="json.apresentation.imageSide.background"
                                        value={values.json.apresentation?.imageSide?.background}
                                        onChange={handleChange}
                                        isInvalid={!!errors?.json?.apresentation?.imageSide?.background}
                                        isValid={touched?.json?.apresentation?.imageSide?.background && !errors?.json?.apresentation?.imageSide?.background}
                                    />
                                    <div className="imagesUpload">
                                        <img
                                            className={`h-100 img-thumbnail ${!!errors?.json?.apresentation?.imageSide?.background ? "is-invalid" : ""}`}
                                            src={
                                                values.json.apresentation?.imageSide?.background !== "" && values.json.apresentation?.imageSide?.background !== null
                                                    ? domainConfig.imageServer +
                                                    "/customizacao/" +
                                                    values.customizacao_id +
                                                    "/" +
                                                    values.json.apresentation?.imageSide?.background
                                                    : domainConfig.imageServer + "/empty-background.png"
                                            }
                                            alt="Imagem footer aplicativo"
                                        />
                                        <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                                        <Form.Control.Feedback type="invalid">
                                            {errors?.json?.apresentation?.imageSide?.background}
                                        </Form.Control.Feedback>
                                    </div>
                                    <DropzoneComponent
                                        className="mt-3 dropzoneComp"
                                        type="image"
                                        handlePreviewSuccess={handlePreviewSuccess}
                                        handlePreviewError={handlePreviewError}
                                        setFieldValue={setFieldValue}
                                        folder="customizacao"
                                        id={values.customizacao_id}
                                        field="json.apresentation.imageSide.background"
                                    />
                                </Card.Body>
                            </Card>
                        </Form.Group>
                    </Row>
                </>
            }
            <Row className="mx-0">
                <Form.Group
                    as={Col}
                    sm="12"
                   
                >
                    <Form.Check
                        className="w-auto"
                        checked={values.json.apresentation.showIsAnswered ? true : false}
                        type="checkbox"
                        name="json.apresentation.showIsAnswered"
                        label="Habilitar botão de responder"
                        onChange={(e) => {
                            setFieldValue(
                                "json.apresentation.showIsAnswered",
                                values.json.apresentation.showIsAnswered ? false : true
                            );
                        }}
                    />
                    <Form.Check
                        className="w-auto"
                        checked={values.json.apresentation.showAuthor ? true : false}
                        type="checkbox"
                        name="json.apresentation.showAuthor"
                        label="Mostrar autor da mensagem"
                        onChange={(e) => {
                            setFieldValue(
                                "json.apresentation.showAuthor",
                                values.json.apresentation.showAuthor ? false : true
                            );
                        }}
                    />

                    <Form.Check
                        checked={values.json.apresentation?.showQRCode.status === true ? true : false}
                        type="checkbox"
                        name="json.apresentation.showQRCode.status"
                        label="Mostrar QRCode"
                        onChange={(e) => {
                            setFieldValue(
                                "json.apresentation.showQRCode.status",
                                values.json.apresentation.showQRCode?.status === true ? false : true
                            );
                        }}
                    />
                    {values.json.apresentation?.showQRCode?.status === true ? (
                        <Row>
                            <Form.Group
                                className="mb-3"
                                as={Col}
                                sm="4"
                               >
                                <Form.Label>Texto</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="json.apresentation.showQRCode.text"
                                    value={values.json.apresentation.showQRCode.text}
                                    onChange={handleChange}
                                    isInvalid={!!errors.apresentation?.showQRCode?.text}
                                    isValid={
                                        touched.json?.apresentation?.showQRCode?.text &&
                                        !errors.json?.apresentation?.showQRCode?.text
                                    }
                                />
                                <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">
                                    {errors.json?.apresentation?.showQRCode?.text}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group
                                className="mb-3"
                                as={Col}
                                sm="4"
                               
                            >
                                <Form.Label>URL<OverlayTrigger overlay={<Tooltip>Caso nenhuma URL seja fornecida o QRCode irá redirecionar o usuário ao evento</Tooltip>}>
                                    <HelpTooltip icon={faExclamationCircle} />
                                </OverlayTrigger></Form.Label>
                                <Form.Control
                                    type="text"
                                    name="json.apresentation.showQRCode.link"
                                    value={values.json.apresentation.showQRCode.link}
                                    onChange={handleChange}
                                    isInvalid={!!errors.apresentation?.showQRCode?.link}
                                    isValid={
                                        touched.json?.apresentation?.showQRCode?.link &&
                                        !errors.json?.apresentation?.showQRCode?.link
                                    }
                                />
                                <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">
                                    {errors.json?.apresentation?.showQRCode?.link}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group
                                className="mb-3"
                                as={Col}
                                sm="4"
                               
                            >
                                <Form.Label>Posição Y</Form.Label>
                                <Form.Select
                                    onChange={(e) => {
                                        setFieldValue("json.apresentation.showQRCode.position.y", e.target.value);
                                    }}
                                    value={values.json.apresentation.showQRCode.position.y}>
                                    <option value={'top'}>
                                        Topo
                                    </option>
                                    <option value={'bottom'}>
                                        Fundo
                                    </option>
                                </Form.Select>
                                <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">
                                    {errors.json?.apresentation?.showQRCode?.position?.y}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group
                                className="mb-3"
                                as={Col}
                                sm="4"
                               
                            >
                                <Form.Label>Posição X</Form.Label>
                                <Form.Select
                                    onChange={(e) => {
                                        setFieldValue("json.apresentation.showQRCode.position.x", e.target.value);
                                    }}
                                    value={values.json.apresentation.showQRCode.position.x}>
                                    <option value={'left'}>
                                        Esquerda
                                    </option>
                                    <option value={'right'}>
                                        Direita
                                    </option>
                                </Form.Select>
                                <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">
                                    {errors.json?.apresentation?.showQRCode?.position?.x}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                    ) : (
                        ""
                    )}
                </Form.Group>
            </Row>
        </>
    );
};

export default QA