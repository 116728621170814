import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { format, formatInTimeZone } from "date-fns-tz";
import { ptBR } from "date-fns/locale";
import { getUnixTime } from "date-fns";
import {
  jsonEvent
} from "../../components/Json.js";
import { safeJSONParse, fillMissingProperties } from '../../assets/functions'
import { statusX, colorsX } from '../../components/Events'

function convertDateToDisplay(date, tipo) {
  const dateX = new Date(date);
  if (tipo === 1) {
    const datetime = format(dateX, "dd/MM");
    return datetime;
  } else {
    const datetime = format(dateX, "dd/MM/yyyy HH:mm");
    return datetime;
  }
}

function getPorcentagem(data_criacaoProps, data_terminoProps) {
  const data_criacao = new Date(data_criacaoProps);
  const data_criacaoTime = getUnixTime(data_criacao);
  const data_termino = new Date(data_terminoProps);
  const data_terminoTime = getUnixTime(data_termino);
  const dateNowTime = getUnixTime(new Date());
  var result = Math.round(
    ((dateNowTime - data_criacaoTime) / (data_terminoTime - data_criacaoTime)) *
    100
  );
  if (result >= 100) {
    result = 100;
  } else if (result <= 0) {
    result = 0;
  }
  return result;
}

const initialState = {
  value: false,
  status: "idle",
  setTitleEvent: "",
  setDatetimeEvent: 0,
  setDatetimeOrder: 0,
  limit: 15,
  firstFetch: true,
  currentPage: 1,
  lastPage: 1,
  stopFetching: false,
  pageFetching: false,
  setID: undefined,
  updateID: 0,
  platform: undefined,
  setStatusEvent: [
    { value: 0, text: statusX[0], isActive: false },
    { value: 1, text: statusX[1], isActive: false },
    { value: 2, text: statusX[2], isActive: false },
    { value: 3, text: statusX[3], isActive: false },
    { value: 4, text: statusX[4], isActive: false },
  ]
};

const dataTreatment = (value, index, type) => {
  if (value.aplicativos) {
    value.aplicativos.forEach((element) => {
      element.json = safeJSONParse(element.json);
    });
  };
  value["json"] = safeJSONParse(value.json);
  if (typeof value["app_details"] === 'string') {
    try {
      value["app_details"] = JSON.parse(value["app_details"]);
    } catch (e) {
      console.error("Failed to parse JSON:", e);
      value["app_details"] = [];
    }
  }
  if (Array.isArray(value["app_details"]) && value["app_details"].length > 0) {
    value["app_details"] = Object.values(
      value["app_details"].reduce((acc, item) => {
        if (!acc[item.aplicativo_id]) {
          acc[item.aplicativo_id] = {
            aplicativo_id: item.aplicativo_id,
            nomeAplicativo: item.nomeAplicativo
          };
        }
        return acc;
      }, {})
    );
  }

  value["porcentagem"] = getPorcentagem(
    value["data_criacao"],
    value["data_termino"]
  );
  value["data_c"] = convertDateToDisplay(value["data_criacao"], 1);
  value["data_t"] = convertDateToDisplay(value["data_termino"], 1);
  value["data_criacao_formatted"] = formatInTimeZone(value["data_criacao"], "UTC", "d 'de' MMMM", { locale: ptBR });
  value["data_termino_formatted"] = formatInTimeZone(value["data_termino"], "UTC", "d 'de' MMMM", { locale: ptBR });
  value["data_criacao"] = convertDateToDisplay(value["data_criacao"], 2);
  value["data_termino"] = convertDateToDisplay(value["data_termino"], 2);

  if (value["last_external_update"]) {
    value["last_external_updateD"] = convertDateToDisplay(value["last_external_update"], 2)
  }
  value["duracao"] = value["data_c"] + " até " + value["data_t"];
  value["statusString"] = statusX[value["status"]];
  value["cor"] = colorsX[value["status"]];

  if (type === 2) {
    let json = jsonEvent(value.platform)
    value.json = fillMissingProperties(value.json, json);
    value.json.form.fields = value.json.form.fields.map(element => {
      if (value.json.form.fieldsToShow) {
        let indexToUpdate = value.json.form.fieldsToShow.findIndex(field => field === element.inputID);
        if (indexToUpdate !== -1) {
          return {
            ...element,
            fieldToShow: true // Set to true since it is found in fieldsToShow
          };
        } else {
          return {
            ...element,
            fieldToShow: false // Set to false since it is not found in fieldsToShow
          };
        }
      } else {
        return {
          ...element,
          fieldToShow: false // Set to false if fieldsToShow is undefined or null
        };
      }
    });
  }
};

export const getEvents = createAsyncThunk(
  "events/axiosGet",
  async ({ selectEventsData, user_id, setLoading }) => {
    console.log('createAsyncThunk')
    var filterStatus = `status=${encodeURIComponent(
      JSON.stringify(selectEventsData.setStatusEvent)
    )}`;
    var filterTitle = `titulo=${selectEventsData.setTitleEvent}`;
    var url;
    if (selectEventsData.setID) {
      url = `/api/event/${selectEventsData.setID}?user_id=${user_id}&platform=${selectEventsData.platform}`;
    } else {
      url = `/api/event?user_id=${user_id}&${filterStatus}&${filterTitle}&order=${selectEventsData.setDatetimeOrder}&data_criacao=${selectEventsData.setDatetimeEvent}&platform=${selectEventsData.platform}&pagination=${selectEventsData.currentPage}&lastPage=${selectEventsData.lastPage}&limit=${selectEventsData.limit}`;
    }
    var response = await axios.get(url);
    setLoading(false)
    return {
      events: response.data.message,
      currentPage: response.data.pagination,
      stopFetching: response.data.stopFetching
    };
  }
);

export const eventsSlice = createSlice({
  name: "events",
  initialState,
  reducers: {
    setTitleEvent: (state, action) => {
      state.setID = initialState.setID;
      state.setTitleEvent = action.payload;
      state.firstFetch = true
      state.currentPage = initialState.currentPage;
      state.lastPage = initialState.lastPage;
      state.stopFetching = initialState.stopFetching;
      state.pageFetching = initialState.pageFetching;
    },
    setDatetimeEvent: (state, action) => {
      state.setDatetimeEvent = action.payload;
      state.currentPage = initialState.currentPage;
      state.lastPage = initialState.lastPage;
      state.pageFetching = initialState.pageFetching;
      state.stopFetching = initialState.stopFetching;
      state.firstFetch = initialState.firstFetch;
    },
    setStatusEvent: (state, action) => {
      state.setStatusEvent = action.payload;
      state.currentPage = initialState.currentPage;
      state.lastPage = initialState.lastPage;
      state.pageFetching = initialState.pageFetching;
      state.stopFetching = initialState.stopFetching;
      state.firstFetch = initialState.firstFetch;
    },
    setDatetimeOrder: (state, action) => {
      state.setDatetimeOrder = action.payload;
      state.currentPage = initialState.currentPage;
      state.lastPage = initialState.lastPage;
      state.pageFetching = initialState.pageFetching;
      state.stopFetching = initialState.stopFetching;
      state.firstFetch = initialState.firstFetch;
    },
    setPlatform: (state, action) => {
      state.platform = action.payload;
      state.currentPage = initialState.currentPage;
      state.lastPage = initialState.lastPage;
      state.pageFetching = initialState.pageFetching;
      state.stopFetching = initialState.stopFetching;
      state.firstFetch = initialState.firstFetch;
    },
    setID: (state, action) => {
      state.platform = action.payload.platform;
      state.setID = action.payload.id;
      state.currentPage = initialState.currentPage;
      state.lastPage = initialState.lastPage;
      state.pageFetching = initialState.pageFetching;
      state.stopFetching = initialState.stopFetching;
      state.firstFetch = initialState.firstFetch;
    },
    setCurrentPage: (state, action) => {
      state.currentPage = state.currentPage + 1;
      state.lastPage = state.lastPage + 1;
      state.pageFetching = true
    },
    eventList: (state, action) => {
      if (action.payload?.reset) {
        if (state.setTitleEvent === '') {
          state.setTitleEvent = initialState.setTitleEvent;
        }
        state.value = initialState.value;
        state.status = initialState.status;
        state.setDatetimeEvent = initialState.setDatetimeEvent;
        state.setDatetimeOrder = initialState.setDatetimeOrder;
        state.firstFetch = initialState.firstFetch;
        state.currentPage = initialState.currentPage;
        state.lastPage = initialState.lastPage;
        state.stopFetching = initialState.stopFetching;
        state.pageFetching = initialState.pageFetching;
        state.setID = initialState.setID;
        state.setStatusEvent = initialState.setStatusEvent;
      } else if (action.payload?.fetch) {
        state.firstFetch = initialState.firstFetch;
        state.pageFetching = true;
        state.updateID = state.updateID + 1;
        state.currentPage = initialState.currentPage;
      } else {
        state.setID = initialState.setID;
        state.value = initialState.value;
        state.platform = initialState.platform;
        state.currentPage = initialState.currentPage;
        state.lastPage = initialState.lastPage;
        state.pageFetching = initialState.pageFetching;
        state.stopFetching = initialState.stopFetching;
        state.firstFetch = initialState.firstFetch;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getEvents.pending, (state) => {
        if (state.pageFetching === false) {
          state.status = "loading";
        }
      })
      .addCase(getEvents.fulfilled, (state, action) => {
        const events = action.payload.events;
        const currentPage = action.payload.currentPage;
        const stopFetching = action.payload.stopFetching;
        if (events.length > 0) {
          events.forEach(function callback(value, index) {
            dataTreatment(value, index, 1);
          });
        } else {
          if (events.evento_id) {
            dataTreatment(events, 0, 2);
          }
        }
        if (!state.firstFetch && currentPage !== state.lastPage) {
          if (stopFetching === true) {
            state.stopFetching = true
          } else {
            state.value = [
              ...state.value,   // Keep the existing events
              ...events         // Add the new events
            ];
          }
        } else {
          state.firstFetch = false
          state.value = events;
        }
        state.currentPage = state.lastPage
        state.pageFetching = false;
        state.status = "idle";
      });
  },
});

export const selectEvents = (state) => state.events;

export const { setTitleEvent, setDatetimeEvent, setDatetimeOrder, setStatusEvent, setPlatform, setID, setCurrentPage, eventList } =
  eventsSlice.actions;

export default eventsSlice.reducer;
