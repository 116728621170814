import React, { useEffect, useState, useRef, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, Navbar, Nav, Form, InputGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import useSidebar from "../../hooks/useSidebar";
import NavbarUser from "./NavbarUser";
import useAppDispatch from "../../hooks/useAppDispatch";
import { setTitleEvent } from "../../redux/slices/events";

// Debounce function using useRef
const useDebounce = (callback, delay) => {
  const timeoutRef = useRef(null);

  return useCallback((...args) => {
    if (timeoutRef.current) clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
      callback(...args);
    }, delay);
  }, [callback, delay]);
};

const NavbarComponent = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { isOpen, setIsOpen } = useSidebar();
  const [navSearch, setNavSearch] = useState("");

  const debouncedSearch = useDebounce((searchTerm) => {
    dispatch(setTitleEvent(searchTerm));

    if (location.pathname !== "/") {
      navigate("/");
    }
  }, 750); // Adjusted debounce delay

  const handleSearch = (e) => {
    const searchTerm = e.target.value;
    setNavSearch(searchTerm);
    debouncedSearch(searchTerm);
  };

  useEffect(() => {
    if (location.pathname !== "/") {
      setNavSearch("");
      dispatch(setTitleEvent(""));
    }
  }, [location.pathname, dispatch]);

  return (
    <Navbar variant="light" expand className="navbar-bg">
      <span
        className="sidebar-toggle d-flex"
        onClick={() => {
          setIsOpen(!isOpen);
        }}><i className="hamburger align-self-center" />
      </span>
      <Form inline="true" onSubmit={(event) => event.preventDefault()} className="d-none d-sm-inline-block">
        <InputGroup className="input-group-navbar">
          <Form.Control
            type="text"
            value={navSearch}
            placeholder={t("Search")}
            aria-label="Search"
            onChange={(e) => {
              setNavSearch(e.target.value);
              handleSearch(e)
            }}
          />
          <Button className="cursor-pointer" variant="" onClick={handleSearch}>
            <FontAwesomeIcon icon={faSearch} className="me-2" size="lg" />
          </Button>
        </InputGroup>
      </Form>
      <Navbar.Collapse>
        <Nav className="navbar-align">
          <NavbarUser />
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default NavbarComponent;
