import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import useLayout from "../hooks/useLayout";
import axios from "axios";

const baseStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#ced4da",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  transition: "border .3s ease-in-out",
};

const activeStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

function DropzoneComponent({
  className,
  type,
  handlePreviewSuccess,
  handlePreviewError,
  setFieldValue,
  handleSelectChange,
  value,
  folder,
  id,
  field,
  leonardoai,
  setFieldID
}) {
  const { setLoading } = useLayout();
  const [files, setFiles] = useState([]);
  const onDrop = useCallback((acceptedFiles, fileRejections) => {
    if (fileRejections[0] !== undefined) {
      setFiles(fileRejections);
    } else {
      setFiles(acceptedFiles);
    }
  }, []);

  switch (type) {
    case "image":
      type = {
        "image/*": [".jpeg", ".png"],
      };
      break;
    case "audio":
      type = {
        "audio/mp3": [".mp3", ".wav"],
      };
      break;
    case "video":
      type = {
        "video/mp4": [".mp4", ".webm"],
      };
      break;
    default:
      type = "image/*,audio/*,video/*";
      break;
  }

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
    accept: type,
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  useEffect(() => {
    files.forEach((file) => {
      if (file.errors) {
        handlePreviewError("Arquivo inválido");
      } else {
        if (file.size > 10000000) {
          handlePreviewError("Arquivo muito grande. Tamanho máximo: 10mb");
        } else {
          const formData = new FormData();
          formData.append("file", file);
          if (leonardoai) {
            formData.append("leonardoai", true);
            formData.append("leonardoaiReference", true);
          }
          setLoading(true)

          axios
            .post(`/api/upload/${folder}/${id}`, formData)
            .then((response) => {
              if (response.data.success === true) {
                if (handleSelectChange) {
                  handleSelectChange(value, field, response.data.message);
                } else {
                  if (setFieldID) {
                    handlePreviewSuccess(
                      response.data.id,
                      setFieldValue,
                      setFieldID
                    );
                  }
                  handlePreviewSuccess(
                    response.data.message,
                    setFieldValue,
                    field
                  );
                }
              } else {
                handlePreviewError("Error");
              }
              setLoading(false)
            })
            .catch((error) => {
              setLoading(false)
              handlePreviewError(error);
            });
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files]);

  return (
    <section className={`w-80 m-auto cursor-pointer ${className}`}>
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <span>Adicionar arquivo</span>
      </div>
    </section>
  );
}

export default DropzoneComponent;
