
import React, {
    useEffect,
    useState,
    useContext
} from "react";
import Chart from "react-apexcharts";
import axios from "axios";
import {
    Container,
    Button,
    Col,
    Breadcrumb,
    Card,
    Form,
    Row, Dropdown, Modal
} from "react-bootstrap";
import { ColumnSortingTable } from "../../components/Table.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSync, faCog } from "@fortawesome/free-solid-svg-icons";
import Loader from "../../components/Loader.js";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import
import NotyfContext from "../../contexts/NotyfContext.js";
import useAuth from "../../hooks/useAuth";
import useAppDispatch from "../../hooks/useAppDispatch";
import useLayout from "../../hooks/useLayout.js";
import {
    eventList
} from "../../redux/slices/events";
import {
    generateDateCategories,
    getExcelFromObject
} from "../../assets/functions.js";
import { statusX } from "../../components/Events.js";
import {
    setHeaders01, setHeaders02, setHeadersResult, setHeadersUsers, processData01, processData02, generateAccessData,
} from "../../components/EventHistory.js";

export const EventHistory01 = ({ dataTable, setShowHistory, handleDelete, updateDataTable, setDataTableUpdate }) => {
    const [historyData, setHistoryData] = useState(false);
    const [updateDataTableWarning, setDataTableUpdateWarning] = useState(false);
    const [triggerExport, setTriggerExport] = useState(false);
    const [triggerExportResult, setTriggerExportResult] = useState(false);
    const userState = useAuth();
    const { setLoading } = useLayout();
    const notyf = useContext(NotyfContext);
    const days = generateDateCategories()
    const [filter, setFilter] = useState({ extraPropsResults: null, extraPropsParticipants: false, customizacao_id: '' });
    const [fields, setFields] = useState([]);
    const [fieldsResult, setFieldsResult] = useState([]);
    const [showImageModal, setShowImageModal] = useState({ show: false, url: '' });
    const dispatch = useAppDispatch();

    const options = {
        chart: {
            zoom: {
                enabled: false,
            },
            toolbar: {
                export: {
                    csv: {
                        filename: dataTable.titulo + ' - Gráfico',
                    },
                    svg: {
                        filename: dataTable.titulo + ' - Gráfico',
                    },
                    png: {
                        filename: dataTable.titulo + ' - Gráfico',
                    }
                }
            }
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            width: [5, 7, 5],
            curve: "straight",
            dashArray: [0, 8, 5],
        },
        markers: {
            size: 0,
            style: "hollow", // full, hollow, inverted
        },
        xaxis: {
            categories: days,
        },
        colors: [
            `#4ba700`
        ],
        tooltip: {
            y: [
                {
                    title: {
                        formatter: function (val) {
                            return val;
                        },
                    },
                },
            ],
        },
        grid: {
            borderColor: "#f1f1f1",
        },
    };

    useEffect(() => {
        let getIndexApp = dataTable.aplicativos.findIndex(field => field.customizacao_id == filter.customizacao_id);
        let applicationType = getIndexApp >= 0 ? dataTable.aplicativos[getIndexApp].aplicativo_id : null
        axios.get(`/api/event/participants/${dataTable.evento_id}?platform=${dataTable.platform}&customizacao_id=${filter.customizacao_id}&type=${applicationType}`)
            .then(function (response) {
                let currentApp
                if (applicationType !== null && dataTable.aplicativos[getIndexApp]) {
                    currentApp = dataTable.aplicativos[getIndexApp]
                }
                let headers = setHeaders01(dataTable.json.form.fields, handleDelete, filter)
                setFields(headers)
                if (response.data.results && response.data.results.length > 0) {
                    setHeadersResult(setFieldsResult, handleDelete)
                } else {
                    setFieldsResult([])
                }
                let data = processData01(dataTable, response.data, currentApp, applicationType, setFields, setFieldsResult, setShowImageModal)

                setHistoryData(data);
                if (updateDataTableWarning === "update") {
                    setDataTableUpdateWarning(false);
                    notyf.open({
                        type: "success",
                        message: "Histórico atualizado com sucesso",
                        ripple: true,
                        dismissible: true,
                    });
                }
                setLoading(false)
            })
            .catch(function (error) {
                setLoading(false)
                notyf.open({
                    type: "danger",
                    message: "Houve um erro, tente novamente",
                    ripple: true,
                    dismissible: true,
                });
                console.log(error);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateDataTable, filter]);

    const data = generateAccessData(historyData.participants)
    return (
        <Container fluid className="p-0">
            <Modal
                show={showImageModal.show}
                size={"lg"}
                onHide={(e) => setShowImageModal(prevState => ({ ...prevState, show: false }))}
                centered
                backdrop
            >
                <Modal.Header closeButton>
                    <Modal.Title>Visualizar imagem</Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center">
                    <img className="w-auto h-100" style={{ maxHeight: "700px" }} src={showImageModal.url} alt="" />
                </Modal.Body>
            </Modal>
            <Button
                variant="primary"
                className="float-end me-2"
                onClick={() => {
                    setDataTableUpdate((prevCount) => prevCount + 1);
                    setDataTableUpdateWarning("update");
                    setLoading(true)
                }}
            >
                <FontAwesomeIcon icon={faSync} />
            </Button>
            <Button
                variant="primary"
                className="float-end me-2"
                onClick={() => {
                    if (userState.plataforma_aplicativos === 1) {
                        setShowHistory(false)
                    } else {
                        dispatch(eventList());
                    }
                }}
            >
                Voltar
            </Button>
            <Breadcrumb>
                <Breadcrumb.Item
                    onClick={() => {
                        dispatch(eventList());
                    }}
                >
                    Eventos
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    Editar evento
                </Breadcrumb.Item>
                <Breadcrumb.Item active>
                    Relatórios
                </Breadcrumb.Item>
            </Breadcrumb>
            <h6 className="text-muted  ms-2 mb-3">
                Consulte os dados coletados durante o evento
            </h6>
            <Card>
                <Card.Body>
                    <p className="mb-0"><b>Evento: </b>{dataTable.titulo}</p>
                    <p className="mb-0"><b>Status: </b>{statusX[dataTable.status]}</p>
                    <p className="mb-0"><b>Duração: </b>{dataTable.data_criacao} até {dataTable.data_termino}</p>
                </Card.Body>
            </Card>
            {(historyData === false || historyData === undefined || fields.length === 0) ?
                (
                    <div className="py-5">
                        <Loader></Loader>
                    </div>
                ) : (
                    <>
                        <Form.Group as={Col} className="textHeaderAplicativo mb-3">
                            <h5 className="card-title mb-0">
                                Total de registros ({historyData.participants.length})
                            </h5>
                        </Form.Group>
                        <h6 className="text-muted m-3">
                            Quantidade de participantes registrados em um período de 30 dias
                        </h6>
                        <div className="pb-3">
                            <Card className="m-3">
                                <Card.Body>
                                    <div className="chart">
                                        <Chart options={options} series={data} type="line" height="350" />
                                    </div>
                                </Card.Body>
                            </Card>
                        </div>
                        <Form.Group as={Col} className="textHeaderAplicativo d-flex justify-content-between align-items-center">
                            <h5 className="card-title mb-0">Lista de participantes</h5>
                            <Dropdown className="d-inline me-2">
                                <Dropdown.Toggle className="bg-white text-primary shadow-sm">
                                    <FontAwesomeIcon icon={faCog} />
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item
                                        onClick={() => {
                                            setTriggerExport(true);
                                        }}
                                    >
                                        Exportar
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                        onClick={() => {
                                            setFilter((prevState) => ({
                                                ...prevState,
                                                extraPropsParticipants: !prevState.extraPropsParticipants
                                            }))
                                        }}
                                    >
                                        {!filter.extraPropsParticipants ? "Mostrar informações extras" : "Esconder informações extras"}
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                        onClick={() => {
                                            let props = { platform: dataTable.platform, evento_id: dataTable.evento_id, customizacao_id: filter.customizacao_id, type: 'removeUsers' }
                                            handleDelete(props, "Ao continuar todos os participante registrados serão excluidos")
                                        }}>
                                        Excluir participantes
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                        onClick={() => {
                                            let props = { platform: dataTable.platform, evento_id: dataTable.evento_id, customizacao_id: filter.customizacao_id, type: 'removeUsersHistory' }
                                            handleDelete(props, "Mantêm o registro mas remove os dados relacionados aos usuários")
                                        }}>
                                        Excluir apenas histórico do aplicativo
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Form.Group>
                        <div className="pb-3">

                            <Row className="m-3">
                                <Form.Label>Selecione um aplicativo para encontrar mais informações do participante</Form.Label>
                                <Form.Group
                                    as={Col}
                                    sm={"4"}
                                >
                                    <Form.Select
                                        value={filter.customizacao_id}
                                        onChange={(e) => {
                                            setFields([]);
                                            setFieldsResult([]);
                                            setFilter((prevState) => ({
                                                ...prevState,
                                                customizacao_id: e.target.value
                                            }))
                                        }}
                                    >
                                        <option value={''}>
                                            Nenhum
                                        </option>
                                        {dataTable.aplicativos.map((element, index) => {
                                            return (
                                                <option key={"fieldType" + index} value={element.customizacao_id}>
                                                    {element.titulo}
                                                </option>
                                            );
                                        })}
                                    </Form.Select>
                                </Form.Group>
                            </Row>
                            <ColumnSortingTable
                                columns={fields}
                                title={dataTable.titulo}
                                data={historyData.history}
                                getCellProps={(cellinfo) => ({
                                    style: {
                                        textAlign: cellinfo.column.id === "icons" || cellinfo.column.id === "media" || cellinfo.column.id === "time" || cellinfo.column.id === "score" || cellinfo.column.id === "currentQuestion" ? "center" : "",
                                        width: cellinfo.column.id === "icons" || cellinfo.column.id === "media" ? "60px" : "",
                                    }
                                })}
                                triggerExport={triggerExport}
                                setTriggerExport={setTriggerExport}
                            />
                        </div>
                        {fieldsResult.length > 0 && filter.customizacao_id > 0 &&
                            <>
                                <Form.Group as={Col} className="textHeaderAplicativo d-flex justify-content-between align-items-center">
                                    <h5 className="card-title mb-0">Resultados</h5>
                                    <Dropdown className="d-inline me-2">
                                        <Dropdown.Toggle className="bg-white text-primary shadow-sm">
                                            <FontAwesomeIcon icon={faCog} />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item
                                                onClick={() => {
                                                    setTriggerExportResult(true);
                                                }}
                                            >
                                                Exportar
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                                onClick={() => {
                                                    setFilter((prevState) => ({
                                                        ...prevState,
                                                        extraPropsResults: !prevState.extraPropsResults
                                                    }))
                                                }}
                                            >
                                                {!filter.extraPropsResults ? "Mostrar informações adicionais" : "Esconder informações adicionais"}
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                                onClick={() => {
                                                    let props = { platform: dataTable.platform, evento_id: dataTable.evento_id, customizacao_id: filter.customizacao_id, type: 'removeUsersResults' }
                                                    handleDelete(props, "Ao continuar todos os resultados relacionados ao aplicativo serão removidos")
                                                }}>
                                                Excluir todos os resultados
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Form.Group>
                                <h6 className="text-muted m-3">
                                    Histórico de resultados do aplicativo
                                </h6>
                                <ColumnSortingTable
                                    columns={fieldsResult}
                                    title={dataTable.titulo}
                                    data={historyData.results}
                                    getCellProps={(cellinfo) => ({
                                        style: {
                                            textAlign: cellinfo.column.id === "icons" || cellinfo.column.id === "media" || cellinfo.column.id === "time" || cellinfo.column.id === "score" || cellinfo.column.id === "currentQuestion" ? "center" : "",
                                            width: cellinfo.column.id === "icons" || cellinfo.column.id === "media" ? "60px" : "",
                                        }
                                    })}
                                    triggerExport={triggerExportResult}
                                    setTriggerExport={setTriggerExportResult}
                                />
                            </>}
                    </>
                )}
        </Container>
    );
};

export const EventHistory02 = ({ dataTable, setShowHistory, handleDelete, updateDataTable, setDataTableUpdate }) => {
    const [historyData, setHistoryData] = useState(false);
    const [updateDataTableWarning, setDataTableUpdateWarning] = useState(false);
    const [triggerExport, setTriggerExport] = useState(false);
    const [triggerExportUsers, setTriggerExportUsers] = useState(false);
    const [showImageModal, setShowImageModal] = useState({ show: false, url: '' });
    const userState = useAuth();
    const { setLoading } = useLayout();
    const notyf = useContext(NotyfContext);
    const days = generateDateCategories()
    const [filter, setFilter] = useState({ extraPropsUsers: false, extraPropsParticipants: false });
    const [fields, setFields] = useState([]);
    const [fieldsUsers, setFieldsUsers] = useState([]);
    const dispatch = useAppDispatch();

    const options = {
        chart: {
            zoom: {
                enabled: false,
            },
            toolbar: {
                export: {
                    csv: {
                        filename: dataTable.titulo + ' - Gráfico',
                    },
                    svg: {
                        filename: dataTable.titulo + ' - Gráfico',
                    },
                    png: {
                        filename: dataTable.titulo + ' - Gráfico',
                    }
                }
            }
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            width: [5, 7, 5],
            curve: "straight",
            dashArray: [0, 8, 5],
        },
        markers: {
            size: 0,
            style: "hollow", // full, hollow, inverted
        },
        xaxis: {
            categories: days,
        },
        colors: [
            `#4ba700`
        ],
        tooltip: {
            y: [
                {
                    title: {
                        formatter: function (val) {
                            return val;
                        },
                    },
                },
            ],
        },
        grid: {
            borderColor: "#f1f1f1",
        },
    };

    function handleExportResult(props) {

        setLoading(true)
        axios.get(`/api/event/collectedParticipants/${props.user_id}?event_id=${dataTable.event_id}`)
            .then(function (response) {
                let data = response.data
                if (data.length > 0) {
                    let headers = []
                    dataTable.json.form.fields.forEach((element) => {
                        headers.push({ value: element.label, inputID: element.inputID });
                    });
                    getExcelFromObject({ filename: dataTable.titulo + ' - ' + props.email, headers, data });
                } else {
                    notyf.open({
                        type: "danger",
                        message: "Nenhum participante coletado",
                        ripple: true,
                        dismissible: true,
                    });
                }
                setLoading(false)
            })
            .catch(function (error) {
                console.log(error)
                setLoading(false)
                notyf.open({
                    type: "danger",
                    message: "Houve um erro, tente novamente",
                    ripple: true,
                    dismissible: true,
                });
            });
        // let headers = []
    }

    useEffect(() => {
        axios.get(`/api/event/participants/${dataTable.evento_id}?platform=${dataTable.platform}`)
            .then(function (response) {
                setHeaders02(dataTable, setFields, handleDelete, filter, setShowImageModal)
                setHeadersUsers(setFieldsUsers, handleDelete, handleExportResult, filter, setShowImageModal)
                let data = processData02(dataTable,response.data)
                setHistoryData(data);
                if (updateDataTableWarning === "update") {
                    setDataTableUpdateWarning(false);
                    notyf.open({
                        type: "success",
                        message: "Histórico atualizado com sucesso",
                        ripple: true,
                        dismissible: true,
                    });
                }
                setLoading(false)
            })
            .catch(function (error) {
                setLoading(false)
                notyf.open({
                    type: "danger",
                    message: "Houve um erro, tente novamente",
                    ripple: true,
                    dismissible: true,
                });
                console.log(error);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateDataTable, filter]);

    const data = generateAccessData(historyData.history)

    return (
        <Container fluid className="p-0">
            <Modal
                show={showImageModal.show}
                size={"lg"}
                onHide={(e) => setShowImageModal(prevState => ({ ...prevState, show: false }))}
                centered
                backdrop
            >
                <Modal.Header closeButton>
                    <Modal.Title>Visualizar imagem</Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center">
                    <img className="w-auto h-100" style={{ maxHeight: "700px" }} src={showImageModal.url} alt="" />
                </Modal.Body>
            </Modal>
            <Button
                variant="primary"
                className="float-end me-2"
                onClick={() => {
                    setDataTableUpdate((prevCount) => prevCount + 1);
                    setDataTableUpdateWarning("update");
                    setLoading(true)
                }}
            >
                <FontAwesomeIcon icon={faSync} />
            </Button>
            <Button
                variant="primary"
                className="float-end me-2"
                onClick={() => {
                    if (userState.plataforma_eventos === 1) {
                        setShowHistory(false)
                    } else {
                        dispatch(eventList());
                    }
                }}
            >
                Voltar
            </Button>
            <Breadcrumb>
                <Breadcrumb.Item
                    onClick={() => {
                        dispatch(eventList());
                    }}
                >
                    Eventos
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    Editar evento
                </Breadcrumb.Item>
                <Breadcrumb.Item active>
                    Relatórios
                </Breadcrumb.Item>
            </Breadcrumb>
            <h6 className="text-muted my-3">
                Consulte os dados coletados durante o evento
            </h6>
            <Card>
                <Card.Body>
                    <p className="mb-0"><b>Evento: </b>{dataTable.titulo}</p>
                    <p className="mb-0"><b>Status: </b>{statusX[dataTable.status]}</p>
                    <p className="mb-0"><b>Duração: </b>{dataTable.data_criacao} até {dataTable.data_termino}</p>
                </Card.Body>
            </Card>
            {(historyData === false || historyData === undefined || fields.length === 0) ?
                (
                    <div className="py-5">
                        <Loader></Loader>
                    </div>
                ) : (
                    <>

                        <Form.Group as={Col} className="textHeaderAplicativo mb-3">
                            <h5 className="card-title mb-0">
                                Total de registros ({historyData.participants.length})</h5>
                        </Form.Group>
                        <h6 className="text-muted m-3">
                            Quantidade de participantes registrados em um período de 30 dias
                        </h6>
                        <Card className="pb-3">
                            <Card.Body className="m-3">
                                <div className="chart">
                                    <Chart options={options} series={data} type="line" height="350" />
                                </div>
                            </Card.Body>
                        </Card>

                        <Form.Group as={Col} className="textHeaderAplicativo d-flex justify-content-between align-items-center">
                            <h5 className="card-title mb-0">Lista de usuários</h5>
                            <Dropdown className="d-inline me-2">
                                <Dropdown.Toggle className="bg-white text-primary shadow-sm">
                                    <FontAwesomeIcon icon={faCog} />
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item
                                        onClick={() => {
                                            setTriggerExportUsers(true);
                                        }}
                                    >
                                        Exportar
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                        onClick={() => {
                                            setFilter((prevState) => ({
                                                ...prevState,
                                                extraPropsUsers: !prevState.extraPropsUsers
                                            }))
                                        }}
                                    >
                                        {!filter.extraPropsUsers ? "Mostrar informações adicionais" : "Esconder informações adicionais"}
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                        onClick={() => {
                                            let props = { platform: dataTable.platform, evento_id: dataTable.evento_id, type: 'removeUsersMod' }
                                            handleDelete(props, "Ao continuar todos os usuários registrados serão excluidos")
                                        }}>
                                        Excluir usuários
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                        onClick={() => {
                                            let props = { platform: dataTable.platform, evento_id: dataTable.evento_id, type: 'removeUsersModHistory' }
                                            handleDelete(props, "Mantêm os registros mas remove os dados relacionados aos usuários")
                                        }}>
                                        Excluir apenas histórico dos usuários
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Form.Group>
                        <h6 className="text-muted m-3">
                            Consulte os dados coletados pelos usuários moderadores
                        </h6>
                        <div className="pb-3">
                            <ColumnSortingTable
                                columns={fieldsUsers}
                                title={dataTable.title + ' - usuários'}
                                data={historyData.users}
                                getCellProps={(cellinfo) => ({
                                    className: cellinfo.column.id === "icons" ? "table-icons" : "",
                                    style: {
                                        padding: cellinfo.column.id === "imagem" ? "0px 10px" : "",
                                        width: cellinfo.column.id === "imagem" ? "32px" : "",
                                    },
                                })}
                                triggerExport={triggerExportUsers}
                                setTriggerExport={setTriggerExportUsers}
                            />
                        </div>
                        <Form.Group as={Col} className="textHeaderAplicativo d-flex justify-content-between align-items-center">
                            <h5 className="card-title mb-0">Lista de participantes</h5>
                            <Dropdown className="d-inline me-2">
                                <Dropdown.Toggle className="bg-white text-primary shadow-sm">
                                    <FontAwesomeIcon icon={faCog} />
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item
                                        onClick={() => {
                                            setTriggerExport(true);
                                        }}
                                    >
                                        Exportar
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                        onClick={() => {
                                            setFilter((prevState) => ({
                                                ...prevState,
                                                extraPropsParticipants: !prevState.extraPropsParticipants
                                            }))
                                        }}
                                    >
                                        {!filter.extraPropsParticipants ? "Mostrar informações adicionais" : "Esconder informações adicionais"}
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                        onClick={() => {
                                            let props = { platform: dataTable.platform, evento_id: dataTable.evento_id, type: 'removeUsers' }
                                            handleDelete(props, "Ao continuar todos os participante registrados serão excluidos")
                                        }}>
                                        Excluir participantes
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                        onClick={() => {
                                            let props = { platform: dataTable.platform, evento_id: dataTable.evento_id, type: 'removeUsersHistory' }
                                            handleDelete(props, "Mantêm os registros mas remove os dados relacionados aos usuários")
                                        }}>
                                        Excluir apenas histórico dos participantes
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Form.Group>
                        <h6 className="text-muted m-3">
                            Encontre informações de registro e resultados associados aos participantes
                        </h6>
                        <ColumnSortingTable
                            key={'olumnparticipantes' + fields.length}
                            columns={fields}
                            title={dataTable.title + ' - participantes'}
                            data={historyData.participants}
                            getCellProps={(cellinfo) => ({
                                className: cellinfo.column.id === "icons" ? "table-icons" : "",
                                style: {
                                    padding: cellinfo.column.id === "imagem" ? "0px 10px" : "",
                                    width: cellinfo.column.id === "imagem" ? "32px" : "",
                                },
                            })}
                            triggerExport={triggerExport}
                            setTriggerExport={setTriggerExport}
                        />
                    </>
                )}
        </Container>
    );
};