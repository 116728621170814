import "react-app-polyfill/stable";

import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import axios from "axios";
import reportWebVitals from "./utils/reportWebVitals";
import App from "./App";
import { domainConfig } from "./config";
import "./mocks";

axios.defaults.baseURL = process.env.NODE_ENV === "development" ? "http://localhost:8080" : domainConfig.restAPI;
// console.log(process.env.REACT_APP_API_KEY)
// axios.defaults.headers.common['x-api-key'] = `${process.env.REACT_APP_API_KEY}`;

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
