import React, {
  useEffect,
  useState,
  useContext
} from "react";
import {
  Container,
  Button,
  Col,
  Breadcrumb,
  Card,
  Badge,
  Row,
  Dropdown,
  DropdownButton
} from "react-bootstrap";
import axios from "axios";
import Loader from "../../components/Loader.js";
import { confirmAlert } from "react-confirm-alert";
import { ShowConfirm } from "../../components/Alerts.js";
import "react-confirm-alert/src/react-confirm-alert.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDisplay,
  faShare,
  faPlus,
  faPersonChalkboard,
  faAddressCard,
  faCheckCircle
} from "@fortawesome/free-solid-svg-icons";
import { domainConfig } from "../../config.js";
import illustration from "../../assets/img/illustrations/curiosity search-bro.png";
import NotyfContext from "../../contexts/NotyfContext";
import useAppSelector from "../../hooks/useAppSelector";
import useAppDispatch from "../../hooks/useAppDispatch";
import useAuth from "../../hooks/useAuth";
import useLayout from "../../hooks/useLayout";
import {
  setID,
  setCurrentPage,
  getEvents,
  selectEvents,
  eventList
} from "../../redux/slices/events";
import {
  FilterDate, FilterStatus, EventType
} from "../../components/Events.js";
import { EventEdit } from './EventEdit.js'
import {
  jsonEvent
} from "../../components/Json.js";
import { redirectURL, convertDateToDisplay, handleShareLink, defaultCreationDate, defaultEndDate, useInfiniteScroll } from "../../assets/functions"

const Events = () => {
  const dispatch = useAppDispatch();
  const userState = useAuth();
  const [updateDataTable, setDataTableUpdate] = useState(false);
  const { setLoading } = useLayout();
  const selectEventsData = useAppSelector(selectEvents);
  const [fonts, setFonts] = useState(false);
  const [aplicativos, setAplicativos] = useState(false);

  useEffect(() => {
    if (!fonts) {
      //Get google fonts
      axios
        .get(
          `https://www.googleapis.com/webfonts/v1/webfonts?key=AIzaSyBQSHG64iczeP5lb7OvezR9kkn0LSU8vEc&sort=popularity`
        )
        .then((response) => {
          let placeIDs = [];
          response.data.items.slice([0], [100]).map((item, i) => {
            return placeIDs.push({ font: item.family });
          });
          setFonts(placeIDs);
        })
        .catch((error) => {
          console.log(error);
        });
    }
    if (!aplicativos) {
      axios
        .get("/api/application?status=1")
        .then(function (response) {
          setAplicativos(response["data"]["message"]);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }, [aplicativos, fonts]);

  useEffect(() => {
    dispatch(getEvents({ selectEventsData, user_id: userState.user_id, setLoading }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectEventsData.updateID, selectEventsData.lastPage, selectEventsData.setForm, selectEventsData.setTitleEvent, selectEventsData.setStatusEvent, selectEventsData.setDatetimeEvent, selectEventsData.setDatetimeOrder, selectEventsData.setID])

  if (!fonts || !aplicativos || !selectEventsData.value) {
    return <Loader></Loader>;
  }

  if (selectEventsData.setID > 0 && selectEventsData.value.evento_id) {
    // eslint-disable-next-line eqeqeq

    return (
      <EventEdit
        key="eventEdit"
        dataTable={selectEventsData.value}
        setDataTableUpdate={setDataTableUpdate}
        fonts={fonts}
        aplicativos={aplicativos}>
      </EventEdit>
    )
  } else {

    return (
      <EventList
        key="EventList"
        selectEventsData={selectEventsData}>
      </EventList>
    );
  }
};

const EventList = ({
  selectEventsData
}) => {
  const dispatch = useAppDispatch();
  const userState = useAuth();
  const { setLoading, scrollContainerRef } = useLayout();
  const [showModalEventType, setShowModalEventType] = useState(false);
  const notyf = useContext(NotyfContext);

  function handleDelete(props, platform) {
    confirmAlert({
      closeOnEscape: false,
      closeOnClickOutside: false,
      customUI: ({ onClose }) => {
        return (
          <ShowConfirm
            title="Confirmar ação"
            description="Deseja remover esse evento? O link de acesso ao aplicativo será desativado e todos os dados relacionados ao evento serão excluídos."
            action={deleteEvent}
            onClose={onClose}
            param={{ props, platform }}
          />
        );
      },
    });
  }

  function deleteEvent({ props, platform }) {
    setLoading(true)
    axios
      .delete(`/api/event/${props.evento_id}`, {
        data: { platform: platform },
      })
      .then(function (response) {
        dispatch(eventList({ fetch: true }));
      })
      .catch(function (error) {
        setLoading(false)
        notyf.open({
          type: "danger",
          message: "Houve um erro, tente novamente",
          ripple: true,
          dismissible: true,
        });
      });
  }

  function registerToggle(props, status, platform) {
    const updatedValues = JSON.parse(JSON.stringify(props));
    updatedValues.json.form.status = status
    setLoading(true)
    axios.put(`/api/event/${props.evento_id}`, {
      json: JSON.stringify(updatedValues.json),
      platform: platform
    })
      .then(function (response) {
        if (status) {
          notyf.open({
            type: "success",
            message: "Inscrição ativada com sucesso",
            ripple: true,
            dismissible: true,
          });
        } else {
          notyf.open({
            type: "success",
            message: "Inscrição desativada com sucesso",
            ripple: true,
            dismissible: true,
          });
        }
        dispatch(eventList({ fetch: true }));
      })
      .catch(function (error) {
        setLoading(false)
        notyf.open({
          type: "danger",
          message: "Houve um erro, tente novamente",
          ripple: true,
          dismissible: true,
        });
      });
  }

  function handleEndEvent(props, platform) {
    confirmAlert({
      closeOnEscape: false,
      closeOnClickOutside: false,
      customUI: ({ onClose }) => {
        return (
          <ShowConfirm
            title="Confirmar ação"
            description="Deseja finalizar esse evento? O link de acesso ao aplicativo será desativado."
            action={endEvent}
            onClose={onClose}
            param={{ props, platform }}
          />
        );
      },
    });
  }

  function startEvent(e, props) {
    e.preventDefault();
    e.stopPropagation();
    setLoading(true)
    axios.put(`/api/event/${props.evento_id}`, {
      status: 2,
      platform: props.platform
    })
      .then(function (response) {
        notyf.open({
          type: "success",
          message: "Evento iniciado com sucesso",
          ripple: true,
          dismissible: true,
        });
        dispatch(eventList({ fetch: true }));
      })
      .catch(function (error) {
        setLoading(false)
        notyf.open({
          type: "danger",
          message: "Houve um erro, tente novamente",
          ripple: true,
          dismissible: true,
        });
      });
  }

  function endEvent({ props, platform }) {
    setLoading(true)
    axios.put(`/api/event/${props.evento_id}`, {
      status: 4,
      platform: platform
    })
      .then(function (response) {
        notyf.open({
          type: "success",
          message: "Evento finalizado com sucesso",
          ripple: true,
          dismissible: true,
        });
        dispatch(eventList({ fetch: true }));
      })
      .catch(function (error) {
        setLoading(false)
        notyf.open({
          type: "danger",
          message: "Houve um erro, tente novamente",
          ripple: true,
          dismissible: true,
        });
      });
  }

  function createEvent(platform) {
    let data
    let token = Math.random().toString(36).substr(2, 6).toUpperCase();
    if (platform === 1) {
      data = {
        user_id: userState.user_id,
        titulo: "",
        descricao: "",
        json: JSON.stringify(jsonEvent(platform)),
        status: 0,
        data_criacao: convertDateToDisplay(defaultCreationDate()),
        data_termino: convertDateToDisplay(defaultEndDate()),
        token: token,
        platform: platform
      };
    } else {
      data = {
        user_id: userState.user_id,
        title: "",
        description: "",
        json: JSON.stringify(jsonEvent(platform)),
        status: 0,
        creation_date: convertDateToDisplay(defaultCreationDate()),
        end_date: convertDateToDisplay(defaultEndDate()),
        token: token,
        external_form_id: null,
        last_External_update: null,
        platform: platform
      };
    }
    setLoading(true)
    axios
      .post("/api/event", data)
      .then(function (response) {
        let id = response.data.message.insertId
        dispatch(setID({ id: id, platform: platform }))
        setLoading(false)
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false)
        notyf.open({
          type: "danger",
          message: "Houve um erro, tente novamente",
          ripple: true,
          dismissible: true,
        });
      });
  }

  useInfiniteScroll(scrollContainerRef, dispatch, setCurrentPage, selectEventsData);

  return (
    <>
      <Container className="p-0 d-flex flex-column container h-100 w-100">
        <Row className="mx-0">
          <Col xs="auto" className="order-1 px-0 me-3">
            <Breadcrumb>
              <Breadcrumb.Item active>Eventos</Breadcrumb.Item>
            </Breadcrumb>
            <h6 className="text-muted mb-3">
              Gerenciamento e criação de eventos
            </h6>
          </Col>
          {/* Filters */}
          {(userState.plataforma_aplicativos === 1 || userState.plataforma_eventos === 1) && <Col
            xs="auto"
            className="order-sm-2 order-3 mb-sm-0 mb-3 px-0 ms-sm-auto ms-0"
          >
            <FilterDate />
            <FilterStatus selectEventsData={selectEventsData} />
            <Button
              variant="primary"
              className="shadow-sm"
              onClick={() => setShowModalEventType(true)}
            >
              <FontAwesomeIcon icon={faPlus} size="1x" />
            </Button>
          </Col>}
        </Row>
        {selectEventsData.status === 'loading' ? (
          <Loader></Loader>
        ) : (
          <>
            {((selectEventsData.value && Object.keys(selectEventsData.value).length === 0) || selectEventsData.value === undefined) ? (
              <div className="m-auto text-center">
                <img
                  src={illustration}
                  alt="Searching"
                  className="img-fluid illustration-img mb-4"
                />
                <p className="opacity-75">Não foi encontrado nenhum evento...</p>
              </div>
            ) : (
              <>
                <Row>
                  {selectEventsData.value && selectEventsData.value.length > 0 && selectEventsData.value.map((props, index) => {
                    return (
                      <EventCard key={'eventCard' + props.platform + props.evento_id + selectEventsData.updateID} props={props} startEvent={startEvent} registerToggle={registerToggle} handleDelete={handleDelete} handleEndEvent={handleEndEvent}></EventCard>
                    )
                  }
                  )}
                </Row>
                {selectEventsData.value.length >= selectEventsData.limit && selectEventsData.stopFetching === false && <div className={`container py-3 pageLoaderContainer ${selectEventsData.pageFetching === true ? 'opacity-1' : 'opacity-0'}`} initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { duration: .5 } }} exit={{ opacity: 0 }}>
                  <Loader></Loader>
                </div>}
              </>
            )}
          </>
        )}
      </Container>
      <EventType
        createEvent={createEvent}
        showModalEventType={showModalEventType}
        setShowModalEventType={setShowModalEventType}
      />
    </>
  );
};

const EventLogo = ({ props }) => {
  let origin = props.platform === 2 ? 'plataforma-eventos' : 'evento'
  let link = domainConfig.imageServer
  if (!props.json) {
    return <></>
  }

  function handleStyle(props, evento_id) {
    if (props.background.type === 'image') {
      return {
        background: `url(${link + "/" + origin + "/" + evento_id + '/' + props.background.url}) no-repeat center center / cover`,
        height: '100%'
      }
    } else if (props.background.type === 'video') {
      return {
        background: `url(${link + "/" + origin + "/" + evento_id + '/' + props.background.url}) no-repeat center center / cover`,
        height: '100%'
      }
    } else {
      return {
        backgroundColor: `${props.background.color}`
      }
    }
  }

  if (props.json.logo === "" || props.json?.logo === null) {
    return (<></>)
  }

  if (props.json.background.type === 'video') {
    return (
      <div className="position-relative d-flex">
        <video className="videoBackground" autoPlay loop muted playsInline src={`${link + "/" + origin + "/" + props.evento_id + "/" + props.json.background.url}`}></video>
        <div style={{ padding: "25px 50px", zIndex: 1, width: '100%' }}>
          <div style={{ backgroundColor: props.json.transparency, boxShadow: '0 .2rem 0.75rem ' + props.json.boxShadow, borderRadius: '5px' }}>
            <img className="card-img-top" src={link + "/" + origin + "/" + props.evento_id + '/' + props.json.logo} alt="logo-evento"></img>
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <div style={handleStyle(props.json, props.evento_id)}>
        <div style={{ padding: "25px 50px", zIndex: 1, width: '100%' }}>
          <div style={{
            backgroundColor: props.json.transparency, boxShadow: '0 .2rem 0.75rem ' + props.json.boxShadow, borderRadius: '5px'
          }}>
            <img className="card-img-top" src={link + "/" + origin + "/" + props.evento_id + '/' + props.json.logo} alt="logo-evento"></img>
          </div>
        </div>
      </div>
    )
  }
}

const EventCard = ({ props, startEvent, registerToggle, handleDelete, handleEndEvent }) => {
  const dispatch = useAppDispatch();
  const selectEventsData = useAppSelector(selectEvents);
  const userState = useAuth();
  const notyf = useContext(NotyfContext);

  return (
    <Col md="6" lg="4" sm="6" xl="4">
      <Card className="mainCard">
        <div className={`card-top justify-content-between bg-${props["cor"]}`}>
          <span>
            {props["statusString"]}
          </span>
          <div className="d-inline float-end">
            <DropdownButton
              className={"text-white dropdownEvent"}
              title={''}
            >
              {((userState.plataforma_aplicativos === 1 && props.platform) === 1 || (userState.plataforma_eventos === 1 && props.platform) === 2) && props.status === 2 &&
                <>
                  {props.platform === 1 &&
                    <>
                      {props.json.form.status ?
                        (
                          <Dropdown.Item eventKey="1" onClick={() => registerToggle(props, false, 1)}>
                            Desativar inscrições
                          </Dropdown.Item>
                        ) : (
                          <Dropdown.Item eventKey="2" onClick={() => registerToggle(props, true, 1)}>
                            Ativar inscrições
                          </Dropdown.Item>
                        )}
                    </>}
                  <Dropdown.Item eventKey="3" onClick={() => handleEndEvent(props, 1)}>
                    Finalizar evento
                  </Dropdown.Item>
                </>}
              {((userState.plataforma_aplicativos === 1 && props.platform) === 1 || (userState.plataforma_eventos === 1 && props.platform) === 2) &&
                <>
                  <Dropdown.Item eventKey="3" onClick={() => handleDelete(props, props.platform)}>
                    Excluir evento
                  </Dropdown.Item>
                  <Dropdown.Divider />
                </>}
              <Dropdown.Item
                eventKey="4"
                onClick={() => {
                  handleShareLink(props.platform === 1 ? domainConfig.aplicativos : domainConfig.eventos, props, false, notyf);
                }}
              >
                <FontAwesomeIcon
                  icon={faShare}
                  className="me-2"
                />
                Compartilhar link de acesso
              </Dropdown.Item>
              {props.platform === 1 && <Dropdown.Item
                eventKey="5"
                onClick={() => {
                  redirectURL(true, domainConfig.aplicativos + "/?token=" + props.token + "&presenter=" + props.codigo_presenter);
                }}
              >
                <FontAwesomeIcon
                  icon={faPersonChalkboard}
                  className="me-2"
                />
                Acessar modo apresentador
              </Dropdown.Item>}
            </DropdownButton>
          </div>
        </div>
        <div className="clickMainCard"
          onClick={() => {
            if (selectEventsData.pageFetching === true) {
              return
            }
            dispatch(setID({ id: props.evento_id, platform: props.platform }));
          }}>
          <EventLogo props={props}></EventLogo>
          <div className="border-top">
            <div className="p-3">
              <p className="fw-bold mb-2">
                {props.titulo !== "" ? (
                  props["titulo"]
                ) : ("Sem título")}
              </p>
              <p className="mb-2">
                <span className={`badge bg-${props["cor"]}`}>{props.data_criacao_formatted} até {props.data_termino_formatted}</span>
              </p>
              <div className="mb-2">
                {props.platform === 1 ?
                  <span>
                    <FontAwesomeIcon icon={faDisplay} className="me-2" />Plataforma de aplicativos
                  </span> :
                  <span>
                    <FontAwesomeIcon icon={faAddressCard} className="me-2" />Plataforma de eventos
                  </span>
                }
              </div>
              {props["app_details"] && props["app_details"].length > 0 && props["app_details"].map((value, index) => {
                return (
                  <Badge key={'badge' + props.evento_id + selectEventsData.updateID + 'index' + index} bg="light" text="dark" className="mb-2 me-2">{value.nomeAplicativo}</Badge>
                )
              })}
              {props.platform === 1 && <p className="mb-0">
                Inscrição:
                <span className="fw-bold ms-1">
                  {props.json.form.status ? ('Ativo') : ('Desativado')}
                </span>
              </p>}
              {props.platform === 2 && props.external_form_id && props.external_form_id !== null && <p className="mb-0">
                <span className="badge bg-primary"><FontAwesomeIcon icon={faCheckCircle} size="1x" className="me-1" />Integração UEP</span>
              </p>}
              <p className="mb-0">
                Participantes:
                <span className="fw-bold ms-1">
                  {props["total_participantes"]}
                </span>
              </p>
            </div>
          </div>
          {((userState.plataforma_aplicativos === 1 && props.platform) === 1 || (userState.plataforma_eventos === 1 && props.platform) === 2) && props.status === 1 && <Col md={12} className="pb-3 text-center">
            <Button variant="primary" className="btn-sm" onClick={(e) => startEvent(e, props)}>Iniciar evento</Button>
          </Col>}
        </div>
      </Card>
    </Col >
  );
}

export default Events;
