/* eslint-disable eqeqeq */
import React, { useEffect, useState, useContext } from "react";
import { format } from "date-fns-tz";
import { add } from "date-fns";
import * as Yup from "yup";
import axios from "axios";
import {
  Container,
  Button,
  Modal,
  Col,
  Form,
  Breadcrumb,
  Row,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import { ColumnSortingTable } from "../../components/Table.js";
import { Formik, isEmptyArray } from "formik";
import InputMask from "react-input-mask";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useLayout from "../../hooks/useLayout";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { Edit, Trash } from "react-feather";
import { confirmAlert } from "react-confirm-alert";
import { ShowConfirm } from "../../components/Alerts.js";
import Loader from "../../components/Loader.js";
import NotyfContext from "../../contexts/NotyfContext";
import "react-confirm-alert/src/react-confirm-alert.css";
import {
  validateDate, convertDateToDisplay, isDateAfterToday
} from "../../assets/functions.js";

var checkData;
const schema = Yup.object().shape({
  nome: Yup.string()
    .required("Campo obrigatório")
    .max("150", "Máximo de 150 caracteres"),
  email: Yup.string()
    .email("Email inválido")
    .required("Campo obrigatório")
    .max("150", "Máximo de 150 caracteres")
    .test("email", "Email já cadastrado", function (value) {
      if (this.parent.user_id > 0) {
        return true;
      }
      return new Promise((resolve, reject) => {
        async function checkEmail() {
          try {
            if (value !== checkData) {
              checkData = await axios.get(
                `/api/user/checkEmail?email=${value}`
              );
              if (isEmptyArray(checkData.data.message)) {
                resolve(true);
              } else {
                resolve(false);
              }
            } else {
              resolve(false);
            }
          } catch (error) {
            console.error(error);
          }
        }
        checkEmail();
      });
    }),
  inicio_contrato_conta: Yup.string()
    .required("Campo obrigatório")
    .max("255", "Máximo de 255 caracteres")
    .test("date", "Data inválida", function (valueX) {
      if (validateDate(valueX)) {
        return true
      } else {
        return false
      }
    }),
  termino_contrato_conta: Yup.string()
    .required("Campo obrigatório")
    .max("255", "Máximo de 255 caracteres")
    .test("date", "Data inválida", function (valueX) {
      if (validateDate(valueX) && isDateAfterToday(valueX)) {
        return true
      } else {
        return false
      }
    }),
  status: Yup.number().required("Campo obrigatório").integer(),
  tipo: Yup.number().required("Campo obrigatório").integer(),
  plataforma_aplicativos: Yup.number().required("Campo obrigatório").integer(),
  plataforma_eventos: Yup.number().required("Campo obrigatório").integer(),
  aplicativos_customizacao: Yup.number().required("Campo obrigatório").integer(),
});

const tableColumns = [
  {
    Header: "Nome",
    accessor: "nome",
    Filter: false,
  },
  {
    Header: "Email",
    accessor: "email",
    Filter: false,
  },
  {
    Header: "Tipo de usuário",
    accessor: "tipoString",
    Filter: false,
  },
  {
    Header: "Plataforma de Aplicativos",
    accessor: "plataforma_aplicativosString",
    Filter: false,
  },
  {
    Header: "Plataforma de Eventos",
    accessor: "plataforma_eventosString",
    Filter: false,
  },
  {
    Header: "Status",
    accessor: "statusString",
    Filter: false,
  },
  {
    Header: "",
    accessor: "icons",
    disableSortBy: true,
    Filter: false,
  },
];

const TableUsers = () => {
  const [dataTable, setDataTable] = useState(false);
  const [updateDataTable, setDataTableUpdate] = useState(false);
  const notyf = useContext(NotyfContext);
  const { setLoading } = useLayout();

  //Modal data
  const [dataModal, setDataModal] = useState(false);

  //Modal handle
  const [openModals, setShowModal] = useState(false);

  function handleShow(data) {
    setDataModal(data);
    setShowModal(true);
  }

  function handleClose() {
    setDataModal(false);
    setShowModal(false);
  }

  function deleteUser(user_id) {
    setLoading(true)
    axios
      .delete(`/api/user/${user_id}`)
      .then(function (response) {
        setLoading(false)
        setDataTableUpdate((prevCount) => prevCount + 1);
      })
      .catch(function (error) {
        console.log(error)
        setLoading(false)
        if (error.response.status === 500) {
          setLoading(false)
          notyf.open({
            type: "danger",
            message: error.response.data.message ? error.response.data.message : "Não é possivel excluir esse usuário pois existem eventos ou customizações associadas a ele",
            ripple: true,
            dismissible: true,
          });
        } else {
          notyf.open({
            type: "danger",
            message: "Houve um erro, tente novamente",
            ripple: true,
            dismissible: true,
          });
        }
        console.log(error);
      });
  }

  function handleDelete(user_id) {
    confirmAlert({
      closeOnEscape: false,
      closeOnClickOutside: false,
      customUI: ({ onClose }) => {
        return (
          <ShowConfirm
            title="Confirmar ação"
            description="Deseja remover esse usuário? Todas as customizações e eventos associados a ele serão excluidos."
            action={deleteUser}
            onClose={onClose}
            param={user_id}
          />
        );
      },
    });
  }

  useEffect(() => {
    axios
      .get(`/api/user`)
      .then(function (response) {
        var users = response["data"]["message"];
        users.forEach(function callback(value, index) {
          value["icons"] = (
            <>
              <OverlayTrigger overlay={<Tooltip>Editar usuário</Tooltip>}>
                <Edit
                  role="button"
                  className="align-middle me-2 cursor-pointer"
                  onClick={() => {
                    handleShow(value);
                  }}
                  size={18}
                />
              </OverlayTrigger>
              <OverlayTrigger overlay={<Tooltip>Deletar usuário</Tooltip>}>
                <Trash
                  role="button"
                  className="align-middle cursor-pointer"
                  onClick={() => {
                    handleDelete(value["user_id"]);
                  }}
                  size={18}
                />
              </OverlayTrigger>
            </>
          );
          switch (value["status"]) {
            case 0:
              value["statusString"] = "Inativo";
              break;
            case 1:
              value["statusString"] = "Ativo";
              break;
            default:
              break;
          }
          switch (value["tipo"]) {
            case 1:
              value["tipoString"] = "Administrador";
              break;
            case 2:
              value["tipoString"] = "Usuário";
              break;
            default:
              break;
          }
          if (value.plataforma_aplicativos === 0) {
            value.plataforma_aplicativosString = 'Bloqueado'
          } else if (value.plataforma_aplicativos === 1) {
            value.plataforma_aplicativosString = 'Liberado'
          }
          if (value.plataforma_eventos === 0) {
            value.plataforma_eventosString = 'Bloqueado'
          } else if (value.plataforma_eventos === 1) {
            value.plataforma_eventosString = 'Liberado'
          }
          if (value.aplicativos_customizacao === 0) {
            value.aplicativos_customizacaoString = 'Bloqueado'
          } else if (value.aplicativos_customizacao === 1) {
            value.aplicativos_customizacaoString = 'Criação e edição'
          }
        });
        setLoading(false)
        setDataTable(users);
      })
      .catch(function (error) {
        console.log(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateDataTable]);

  //Loading icon
  if (dataTable === false || dataTable === undefined) {
    return <Loader></Loader>;
  }

  return (
    <Container fluid className="p-0 container">
      <Button
        variant="primary"
        className="float-end"
        onClick={() => {
          handleShow(false);
        }}
      ><FontAwesomeIcon icon={faPlus} size="1x" className="me-2" />
        Novo usuário
      </Button>
      <Breadcrumb>
        <Breadcrumb.Item active>
          Usuários
        </Breadcrumb.Item>
      </Breadcrumb>
      <h6 className="text-muted mb-0">
        Edite ou crie os usuários cadastrados no painel de controle
      </h6>
      <Row>
        <Col className="px-0" md={12}>
          <ColumnSortingTable
            columns={tableColumns}
            data={dataTable}
            getCellProps={(cellinfo) => ({
              className: cellinfo.column.id === "icons" ? "table-icons" : "",
              style: {
                padding: cellinfo.column.id === "imagem" ? "0px 10px" : "",
                width: cellinfo.column.id === "imagem" ? "32px" : "",
              },
            })}
          />
          <UserModal
            openModals={openModals}
            dataModal={dataModal}
            handleClose={handleClose}
            setDataTableUpdate={setDataTableUpdate}
          />
        </Col>
      </Row>
    </Container>
  );
};

const UserModal = ({
  openModals,
  dataModal,
  handleClose,
  setDataTableUpdate
}) => {
  const { setLoading } = useLayout();
  const title = dataModal !== false ? "Editar usuário" : "Novo usuário";
  const inicio_contrato_contaObj = new Date();
  const termino_contrato_contaObj = new Date();
  const inicio_contrato_conta = format(inicio_contrato_contaObj, "dd/MM/yyyy HH:mm");
  const termino_contrato_contaObjX = add(termino_contrato_contaObj, { days: "60" });
  const termino_contrato_conta = format(termino_contrato_contaObjX, "dd/MM/yyyy 12:00");
  const notyf = useContext(NotyfContext);

  function createUser(values, actions) {
    setLoading(true)
    const data_criacaoObj = new Date();
    const date_criacao = format(data_criacaoObj, "yyyy-MM-dd HH:mm");
    axios
      .post("/api/user", {
        nome: values.nome,
        email: values.email,
        tipo: values.tipo,
        status: values.status,
        data_criacao: date_criacao,
        plataforma_aplicativos: values.plataforma_aplicativos,
        plataforma_eventos: values.plataforma_eventos,
        aplicativos_customizacao: values.aplicativos_customizacao,
        inicio_contrato_conta: values.inicio_contrato_conta,
        termino_contrato_conta: values.termino_contrato_conta,
      })
      .then(function (response) {
        handleClose();
        setDataTableUpdate((prevCount) => prevCount + 1);
        notyf.open({
          type: "success",
          message: "Conta criada com sucesso. Foi enviado um email com os dados de acesso",
          ripple: true,
          dismissible: true,
        });
      })
      .catch(function (error) {
        handleClose();
        notyf.open({
          type: "danger",
          message: "Houve um erro, tente novamente",
          ripple: true,
          dismissible: true,
        });
      });
  }

  function updateUser(values, actions) {
    setLoading(true)
    axios
      .put(`/api/user/${values.user_id}`, {
        nome: values.nome,
        email: values.email,
        senha: values.senha,
        tipo: values.tipo,
        status: values.status,
        plataforma_aplicativos: values.plataforma_aplicativos,
        plataforma_eventos: values.plataforma_eventos,
        aplicativos_customizacao: values.aplicativos_customizacao,
        inicio_contrato_conta: values.inicio_contrato_conta,
        termino_contrato_conta: values.termino_contrato_conta,
      })
      .then(function (response) {
        handleClose();
        setDataTableUpdate((prevCount) => prevCount + 1);
      })
      .catch(function (error) {
        handleClose();
        notyf.open({
          type: "danger",
          message: "Houve um erro, tente novamente",
          ripple: true,
          dismissible: true,
        });
      });
  }

  return (
    <React.Fragment>
      <Modal show={openModals} onHide={handleClose} size="large" centered>
        <Formik
          validationSchema={schema}
          onSubmit={dataModal !== false ? updateUser : createUser}
          enableReinitialize
          initialValues={{
            user_id: dataModal.user_id !== undefined ? dataModal.user_id : undefined,
            nome: dataModal.nome !== undefined ? dataModal.nome : "",
            email: dataModal.email !== undefined ? dataModal.email : "",
            senha: dataModal.senha !== undefined ? dataModal.senha : false,
            tipo: dataModal.tipo !== undefined ? dataModal.tipo : 2,
            data_criacao: dataModal.data_criacao !== undefined ? convertDateToDisplay(dataModal.data_criacao) : false,
            status: dataModal.status !== undefined ? dataModal.status : 1,
            plataforma_aplicativos: dataModal.plataforma_aplicativos !== undefined ? dataModal.plataforma_aplicativos : 1,
            plataforma_eventos: dataModal.plataforma_eventos !== undefined ? dataModal.plataforma_eventos : 1,
            aplicativos_customizacao: dataModal.aplicativos_customizacao !== undefined ? dataModal.aplicativos_customizacao : 1,
            inicio_contrato_conta: dataModal.inicio_contrato_conta !== undefined ? convertDateToDisplay(dataModal.inicio_contrato_conta) : inicio_contrato_conta,
            termino_contrato_conta: dataModal.termino_contrato_conta !== undefined ? convertDateToDisplay(dataModal.termino_contrato_conta) : termino_contrato_conta,
          }}
        >
          {({
            handleSubmit,
            handleChange,
            values,
            touched,
            isValid,
            errors,
            dirty,
            isSubmitting,
            setFieldValue,
          }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Modal.Header closeButton>{title}</Modal.Header>
              <Modal.Body>
                <Row>
                  <Form.Group as={Col} md="12">
                    <Form.Control
                      type="hidden"
                      name="user_id"
                      value={values.user_id}
                      onChange={handleChange}
                    />
                  </Form.Group>
                  <Form.Group as={Col} md="12">
                    <Form.Label>Nome</Form.Label>
                    <Form.Control
                      className="mb-3"
                      type="text"
                      name="nome"
                      value={values.nome}
                      onChange={handleChange}
                      isInvalid={!!errors.nome}
                      isValid={touched.nome && !errors.nome}
                    />
                    <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                      {errors.nome}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Row>
                <Row>
                  <Form.Group as={Col} md="6">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="email"
                      name="email"
                      onChange={(e) => {
                        if (!values.user_id > 0) {
                          setFieldValue("email", e.target.value);
                        }
                      }}
                      autoComplete="new-password"
                      value={values.email}
                      className={values.user_id > 0 ? "disableInput mb-3" : "mb-3"}
                      isInvalid={!!errors.email}
                      isValid={touched.email && !errors.email}
                    />
                    <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                      {errors.email}
                    </Form.Control.Feedback>
                  </Form.Group>
                  {values.senha && <Form.Group
                    className="mt-sm-0 mt-3"
                    as={Col}
                    md="6">
                    <Form.Label>Senha</Form.Label>
                    <Form.Control
                      type="text"
                      name="senha"
                      autoComplete="new-password"
                      value={values.senha}
                      onChange={handleChange}
                      isInvalid={!!errors.senha}
                      isValid={touched.senha && !errors.senha}
                    />
                    <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                      {errors.senha}
                    </Form.Control.Feedback>
                  </Form.Group>}
                </Row>
                <Row className="mb-3">
                  <Form.Group as={Col} md="6">
                    <Form.Label>Status</Form.Label>
                    <Form.Select
                      className="mb-3"
                      name="status"
                      value={values.status}
                      onChange={handleChange}
                      isInvalid={!!errors.status}
                      isValid={!!errors.status}
                    >
                      <option value="1">Ativo</option>
                      <option value="0">Inativo</option>
                    </Form.Select>
                    <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                      {errors.status}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} md="6">
                    <Form.Label>Tipo de acesso</Form.Label>
                    <Form.Select
                      className="mb-3"
                      name="tipo"
                      value={values.tipo}
                      onChange={handleChange}
                      isInvalid={!!errors.tipo}
                      isValid={!!errors.tipo}
                    >
                      <option value="2">Usuário</option>
                      <option value="1">Administrador</option>
                    </Form.Select>
                    <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                      {errors.tipo}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <p className="text-start fw-bold mb-2">Tempo de vigência</p>
                  {values.data_criacao && <Form.Group
                    className="mb-3"
                    as={Col}
                    md="4"
                    sm={6}
                   
                  >
                    <Form.Label>Data de criação</Form.Label>
                    <InputMask
                      disabled
                      mask="99/99/9999 99:99"
                      value={values.data_criacao}
                      isInvalid={!!errors.data_criacao}
                      isValid={touched.data_criacao && !errors.data_criacao}
                    >
                      {(inputProps) => (
                        <Form.Control
                          disabled
                          {...inputProps}
                          type="text"
                          name="data_criacao"
                        />
                      )}
                    </InputMask>
                    <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                      {errors.data_criacao}
                    </Form.Control.Feedback>
                  </Form.Group>}
                  <Form.Group
                    className="mb-3"
                    as={Col}
                    md="4"
                    sm={6}
                   
                  >
                    <Form.Label>Data de ínicio</Form.Label>
                    <InputMask
                      mask="99/99/9999 99:99"
                      value={values.inicio_contrato_conta}
                      onChange={handleChange}
                      isInvalid={!!errors.inicio_contrato_conta}
                      isValid={touched.inicio_contrato_conta && !errors.inicio_contrato_conta}
                    >
                      {(inputProps) => (
                        <Form.Control
                          {...inputProps}
                          type="text"
                          name="inicio_contrato_conta"
                        />
                      )}
                    </InputMask>
                    <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                      {errors.inicio_contrato_conta}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group
                    className="mb-3"
                    as={Col}
                    md="4"
                    sm={6}
                   
                  >
                    <Form.Label>Data de término</Form.Label>
                    <InputMask
                      mask="99/99/9999 99:99"
                      value={values.termino_contrato_conta}
                      onChange={handleChange}
                      isInvalid={!!errors.termino_contrato_conta}
                      isValid={touched.termino_contrato_conta && !errors.termino_contrato_conta}
                    >
                      {(inputProps) => (
                        <Form.Control
                          {...inputProps}
                          type="text"
                          name="termino_contrato_conta"
                        />
                      )}
                    </InputMask>
                    <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                      {errors.termino_contrato_conta}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Row>
                <Row>
                  <p className="text-start fw-bold mb-2">Criação e edição</p>
                  <Form.Group as={Col} md="6" className="mb-3">
                    <Form.Label>Plataforma de aplicativos</Form.Label>
                    <Form.Select
                      name="plataforma_aplicativos"
                      value={values.plataforma_aplicativos}
                      onChange={handleChange}
                      isInvalid={!!errors.plataforma_aplicativos}
                      isValid={!!errors.plataforma_aplicativos}
                    >
                      <option value="1">Liberado</option>
                      <option value="0">Bloqueado</option>
                    </Form.Select>
                    <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                      {errors.plataforma_aplicativos}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} md="6" className="mb-3">
                    <Form.Label>Plataforma de eventos</Form.Label>
                    <Form.Select
                      name="plataforma_eventos"
                      value={values.plataforma_eventos}
                      onChange={handleChange}
                      isInvalid={!!errors.plataforma_eventos}
                      isValid={!!errors.plataforma_eventos}
                    >
                      <option value="1">Liberado</option>
                      <option value="0">Bloqueado</option>
                    </Form.Select>
                    <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                      {errors.plataforma_eventos}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} md="6" className="mb-3">
                    <Form.Label>Customizações</Form.Label>
                    <Form.Select
                      name="aplicativos_customizacao"
                      value={values.aplicativos_customizacao}
                      onChange={handleChange}
                      isInvalid={!!errors.aplicativos_customizacao}
                      isValid={!!errors.aplicativos_customizacao}
                    >
                      <option value="1">Liberado</option>
                      <option value="0">Bloqueado</option>
                    </Form.Select>
                    <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                      {errors.aplicativos_customizacao}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Row>
              </Modal.Body>
              <Modal.Footer>
                <Button type="submit" disabled={!dirty || isSubmitting}>
                  Salvar
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </React.Fragment>
  );
};

export default TableUsers;
