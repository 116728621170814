import React, { useState } from "react";
import {
    Col,
    Form,
    Row,
    InputGroup,
    Card,
    Button,
    ButtonToolbar,
    Tooltip,
    OverlayTrigger,
} from "react-bootstrap";
import {
    faClock,
    faExclamationCircle
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DropzoneComponent from "../Dropzone.js";
import PopoverPicker from "../../components/PopoverPicker.js";
import { domainConfig } from "../../config.js";
import {
    HelpTooltip
} from "../../assets/functions.js";

const MemoryGame = ({
    handleChange,
    values,
    setFieldValue,
    errors,
    touched,
    handlePreviewSuccess,
    handlePreviewError
}) => {
    const [currentPair, setCurrentPair] = useState(1)
    const pairs = values.json.memoryGame.cards.value.reduce((acc, current) => {
        const duplicate = acc.find(item => item.pair === current.pair);
        if (!duplicate) {
            acc.push(current);
        }
        return acc;
    }, []);

    const [props, setProps] = useState({
        backgroundColor: values.json.apresentation?.textSide?.backgroundColor,
        textColor: values.json.apresentation?.textSide?.textColor,
        firstPlayerColor: values.json.memoryGame.cards.firstPlayerColor,
        firstPlayerBackgroundColor: values.json.memoryGame.cards.firstPlayerBackgroundColor,
        secondPlayerColor: values.json.memoryGame.cards.secondPlayerColor,
        secondPlayerBackgroundColor: values.json.memoryGame.cards.secondPlayerBackgroundColor,
    });

    function newPair() {
        const highestPairObject = values.json.memoryGame.cards.value.reduce((max, current) => {
            return current.pair > max.pair ? current : max;
        }, values.json.memoryGame.cards.value[0]);
        let newID = highestPairObject.pair + 1
        values.json.memoryGame.cards.value.push({
            pair: newID,
            url: ""
        }, {
            pair: newID,
            url: ""
        });
        setCurrentPair(newID);
    }

    function deletePair(pair) {
        values.json.memoryGame.cards.value = values.json.memoryGame.cards.value.filter(item => item.pair !== pair);
        const highestPairObject = values.json.memoryGame.cards.value.reduce((max, current) => {
            return current.pair > max.pair ? current : max;
        }, values.json.memoryGame.cards.value[0]);
        setCurrentPair(highestPairObject.pair);
    }

    return (
        <>
            <Form.Group as={Col} className="textHeaderAplicativo mb-3">
                <h5 className="card-title mb-0">Definições</h5>
            </Form.Group>
            <Row className="mx-0 mb-3">
                <Form.Group
                    className="mb-3"
                    as={Col}
                    sm="12"
                   
                >
                    <Form.Label>Título</Form.Label>
                    <Form.Control
                        type="text"
                        name="titulo"
                        value={values.titulo}
                        onChange={handleChange}
                        isInvalid={!!errors.titulo}
                        isValid={touched.titulo && !errors.titulo}
                    />
                    <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        {errors.titulo}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                    className="mb-3"
                    as={Col}
                    sm="12"
                   
                >
                    <Form.Check
                        className="w-auto"
                        checked={values.json.sound ? true : false}
                        type="checkbox"
                        name="json.sound"
                        label="Ativar sons interativos"
                        onChange={(e) => {
                            setFieldValue(
                                "json.sound",
                                values.json.sound ? false : true
                            );
                        }}
                    />
                    <Form.Check
                        checked={values.json.showRanking ? true : false}
                        type="checkbox"
                        name="json.showRanking"
                        label='Mostrar ranking'
                        onChange={(e) => {
                            setFieldValue(
                                "json.showRanking",
                                values.json.showRanking ? false : true
                            );
                        }}
                    />
                    <Form.Check
                        checked={values.json.timer.status === true ? true : false}
                        type="checkbox"
                        name="json.timer.status"
                        label="Limite de tempo"
                        onChange={(e) => {
                            setFieldValue(
                                "json.timer.status",
                                values.json.timer.status === true ? false : true
                            );
                        }}
                    />
                    {values.json.timer.status === true ? (
                        <>
                            <Form.Group
                                className="my-2"
                                as={Col}
                                sm="6"
                               
                            >
                                <InputGroup>
                                    <InputGroup.Text>
                                        <FontAwesomeIcon icon={faClock} />
                                    </InputGroup.Text>
                                    <Form.Control
                                        type="number"
                                        name="json.timer.value"
                                        value={values.json.timer.value}
                                        onChange={handleChange}
                                        isInvalid={!!errors.json?.timer?.value}
                                        isValid={
                                            touched.json?.timer?.value &&
                                            !errors.json?.timer?.value
                                        }
                                    />
                                    <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid">
                                        {errors.json?.timer?.value}
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </Form.Group>
                        </>
                    ) : (
                        ""
                    )}


                    <Form.Check
                        checked={values.json.reset.status === true ? true : false}
                        type="checkbox"
                        name="json.reset.status"
                        label="Adicionar botão de voltar para página de registro"
                        onChange={(e) => {
                            setFieldValue(
                                "json.reset.status",
                                values.json.reset.status === true ? false : true
                            );
                        }}
                    />
                    {values.json.reset.status === true ? (
                        <>
                            <Form.Group
                                className="my-2"
                                as={Col}
                                sm="6"
                               
                            >
                                <Form.Control
                                    name="json.reset.text"
                                    value={values.json.reset.text}
                                    onChange={handleChange}
                                    isInvalid={!!errors.json?.reset?.text}
                                    isValid={
                                        touched.json?.reset?.text &&
                                        !errors.json?.reset?.text
                                    }
                                />
                                <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">
                                    {errors.json?.reset?.text}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </>
                    ) : (
                        ""
                    )}
                    <Form.Check
                        checked={values.json.memoryGame.gameText.status === true ? true : false}
                        type="checkbox"
                        name="json.memoryGame.gameText.status"
                        label="Texto destacado na tela do minigame"
                        onChange={(e) => {
                            setFieldValue(
                                "json.memoryGame.gameText.status",
                                values.json.memoryGame.gameText.status === true ? false : true
                            );
                        }}
                    />
                    {values.json.memoryGame.gameText.status === true ? (
                        <>
                            <Form.Group
                                className="my-2"
                                as={Col}
                                sm="6"
                               
                            >
                                <Form.Control
                                    as={`textarea`}
                                    name="json.memoryGame.gameText.value"
                                    value={values.json.memoryGame.gameText.value}
                                    onChange={handleChange}
                                    isInvalid={!!errors.json?.memoryGame?.gameText?.value}
                                    isValid={
                                        touched.json?.memoryGame?.gameText?.value &&
                                        !errors.json?.memoryGame?.gameText?.value
                                    }
                                />
                                <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">
                                    {errors.json?.memoryGame?.gameText?.value}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </>
                    ) : (
                        ""
                    )}
                    <Form.Check
                        checked={values.json.memoryGame.allowTwoPlayers.status === true ? true : false}
                        type="checkbox"
                        name="json.memoryGame.allowTwoPlayers.status"
                        label="Habilitar modo com 2 jogadores"
                        onChange={(e) => {
                            setFieldValue(
                                "json.memoryGame.allowTwoPlayers.status",
                                values.json.memoryGame.allowTwoPlayers.status === true ? false : true
                            );
                        }}
                    />
                    {values.json.memoryGame.allowTwoPlayers.status === true ? (
                        <>
                            <Form.Group
                                className="my-2"
                                as={Col}
                                sm="6"
                               
                            >
                                <Form.Label>Tela de seleção</Form.Label>
                                <Form.Control
                                    as={`textarea`}
                                    name="json.memoryGame.allowTwoPlayers.description"
                                    value={values.json.memoryGame.allowTwoPlayers.description}
                                    onChange={handleChange}
                                    isInvalid={!!errors.json?.memoryGame?.allowTwoPlayers?.description}
                                    isValid={
                                        touched.json?.memoryGame?.allowTwoPlayers?.description &&
                                        !errors.json?.memoryGame?.allowTwoPlayers?.description
                                    }
                                />
                                <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">
                                    {errors.json?.memoryGame?.allowTwoPlayers?.description}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Label>Cores (Plano de fundo e texto)</Form.Label>
                            <div className="d-flex mb-3">
                                <div className="me-4">
                                    <Form.Label>1º Jogador</Form.Label>
                                    <div className={`d-flex justify-content-start align-items-center ${errors?.json?.memoryGame?.cards?.firstPlayerColor || errors?.json?.memoryGame?.cards?.firstPlayerBackgroundColor ? 'is-invalid' : ''}`}>
                                        <Form.Control
                                            type="hidden"
                                            name="json.memoryGame.cards.firstPlayerColor"
                                            value={values.json.memoryGame.cards.firstPlayerColor}
                                            isInvalid={!!errors?.json?.memoryGame?.cards?.firstPlayerColor}
                                            isValid={touched?.json?.memoryGame?.cards?.firstPlayerColor && !errors?.json?.memoryGame?.cards?.firstPlayerColor}
                                        />
                                        <Form.Control
                                            type="hidden"
                                            name="json.memoryGame.cards.firstPlayerBackgroundColor"
                                            value={values.json.memoryGame.cards.firstPlayerBackgroundColor}
                                            isInvalid={!!errors?.json?.memoryGame?.cards?.firstPlayerBackgroundColor}
                                            isValid={touched?.json?.memoryGame?.cards?.firstPlayerBackgroundColor && !errors?.json?.memoryGame?.cards?.firstPlayerBackgroundColor}
                                        />
                                        <PopoverPicker
                                            color={props.firstPlayerColor}
                                            onChangeX={(e) => {
                                                setFieldValue("json.memoryGame.cards.firstPlayerColor", e);
                                                setProps(prevState => ({
                                                    ...prevState,
                                                    firstPlayerColor: e
                                                }));
                                            }}
                                            classes={`me-1 picker ${errors?.json?.memoryGame?.cards?.firstPlayerColor ? 'is-invalid' : ''}`}
                                        />
                                        <PopoverPicker
                                            color={props.firstPlayerBackgroundColor}
                                            onChangeX={(e) => {
                                                setFieldValue("json.memoryGame.cards.firstPlayerBackgroundColor", e);
                                                setProps(prevState => ({
                                                    ...prevState,
                                                    firstPlayerBackgroundColor: e
                                                }));
                                            }}
                                            classes={`me-1 picker ${errors?.json?.memoryGame?.cards?.firstPlayerBackgroundColor ? 'is-invalid' : ''}`}
                                        />
                                    </div>
                                    <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                                    <Form.Control.Feedback type="invalid">
                                        {errors?.json?.memoryGame?.cards?.firstPlayerColor ? errors?.json?.memoryGame?.cards?.firstPlayerColor : errors?.json?.memoryGame?.cards?.firstPlayerBackgroundColor ? errors?.json?.memoryGame?.cards?.firstPlayerBackgroundColor : ''}
                                    </Form.Control.Feedback>
                                </div>
                                <div>
                                    <Form.Label>2º Jogador</Form.Label>
                                    <div className={`d-flex justify-content-start align-items-center ${errors?.json?.memoryGame?.cards?.secondPlayerColor || errors?.json?.memoryGame?.cards?.secondPlayerBackgroundColor ? 'is-invalid' : ''}`}>
                                        <Form.Control
                                            type="hidden"
                                            name="json.memoryGame.cards.secondPlayerColor"
                                            value={values.json.memoryGame.cards.secondPlayerColor}
                                            isInvalid={!!errors?.json?.memoryGame?.cards?.secondPlayerColor}
                                            isValid={touched?.json?.memoryGame?.cards?.secondPlayerColor && !errors?.json?.memoryGame?.cards?.secondPlayerColor}
                                        />
                                        <Form.Control
                                            type="hidden"
                                            name="json.memoryGame.cards.secondPlayerBackgroundColor"
                                            value={values.json.memoryGame.cards.secondPlayerBackgroundColor}
                                            isInvalid={!!errors?.json?.memoryGame?.cards?.secondPlayerBackgroundColor}
                                            isValid={touched?.json?.memoryGame?.cards?.secondPlayerBackgroundColor && !errors?.json?.memoryGame?.cards?.secondPlayerBackgroundColor}
                                        />
                                        <PopoverPicker
                                            color={props.secondPlayerColor}
                                            onChangeX={(e) => {
                                                setFieldValue("json.memoryGame.cards.secondPlayerColor", e);
                                                setProps(prevState => ({
                                                    ...prevState,
                                                    secondPlayerColor: e
                                                }));
                                            }}
                                            classes={`me-1 picker ${errors?.json?.memoryGame?.cards?.secondPlayerColor ? 'is-invalid' : ''}`}
                                        />
                                        <PopoverPicker
                                            color={props.secondPlayerBackgroundColor}
                                            onChangeX={(e) => {
                                                setFieldValue("json.memoryGame.cards.secondPlayerBackgroundColor", e);
                                                setProps(prevState => ({
                                                    ...prevState,
                                                    secondPlayerBackgroundColor: e
                                                }));
                                            }}
                                            classes={`me-1 picker ${errors?.json?.memoryGame?.cards?.secondPlayerBackgroundColor ? 'is-invalid' : ''}`}
                                        />
                                    </div>
                                    <Form.Control.Feedback type="invalid">
                                        {errors?.json?.memoryGame?.cards?.secondPlayerColor ? errors?.json?.memoryGame?.cards?.secondPlayerColor : errors?.json?.memoryGame?.cards?.secondPlayerBackgroundColor ? errors?.json?.memoryGame?.cards?.secondPlayerBackgroundColor : ''}
                                    </Form.Control.Feedback>
                                </div>
                            </div>
                        </>
                    ) : (
                        ""
                    )}
                </Form.Group>
                <Form.Group as={Col} sm={4} className='mb-3'>
                    <Form.Label>Tempo de espera após erro (milesimos)</Form.Label>
                    <Form.Control
                        type="number"
                        step={1000}
                        name="json.memoryGame.flipCardTimer"
                        value={values.json.memoryGame.flipCardTimer}
                        onChange={(e) => {
                            handleChange(e);
                        }}
                        isInvalid={!!errors.json?.memoryGame.flipCardTimer}
                    />
                    <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        {errors.json?.memoryGame.flipCardTimer}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} sm={4} className='mb-3'>
                    <Form.Label>Tempo de espera após termino do minigame (milesimos)</Form.Label>
                    <Form.Control
                        type="number"
                        step={1000}
                        name="json.memoryGame.endGameTimer"
                        value={values.json.memoryGame.endGameTimer}
                        onChange={(e) => {
                            handleChange(e);
                        }}
                        isInvalid={!!errors.json?.memoryGame.endGameTimer}
                    />
                    <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        {errors.json?.memoryGame.endGameTimer}
                    </Form.Control.Feedback>
                </Form.Group>
            </Row>
            <Form.Group as={Col} className="textHeaderAplicativo mb-3">
                <h5 className="card-title mb-0">Cartas</h5>
            </Form.Group>
            <Row className="mx-0">
                <Form.Group
                    as={Col}
                    md={4}
                    className="mb-3"
                   >
                    <Form.Label>Cartas por coluna</Form.Label>
                    <Form.Select
                        name="json.memoryGame.cards.cardsPerRow"
                        onChange={handleChange}
                        value={values.json.memoryGame.cards.cardsPerRow}>
                        <option value={6}>2</option>
                        <option value={4}>3</option>
                        <option value={3}>4</option>
                        <option value={2}>6</option>
                    </Form.Select>
                </Form.Group>
                <Form.Group
                    as={Col}
                    md={4}
                    className="mb-3"
                   >
                    <Form.Label>Tamanho máximo (px)</Form.Label>
                    <Form.Control
                        type="number"
                        name="json.memoryGame.cards.width"
                        step={"1"}
                        value={values.json.memoryGame.cards.width}
                        onChange={handleChange}
                        isInvalid={!!errors.json?.memoryGame?.cards?.width}
                        isValid={touched.json?.memoryGame?.cards?.width && !errors.json?.memoryGame?.cards?.width}
                    />
                    <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        {errors.json?.memoryGame?.cards?.width}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                    as={Col}
                    md={4}
                    className="mb-3"
                   >
                    <Form.Label>Raio da borda (px)</Form.Label>
                    <Form.Control
                        type="number"
                        name="json.memoryGame.cards.borderRadius"
                        step={"1"}
                        value={values.json.memoryGame.cards.borderRadius}
                        onChange={handleChange}
                        isInvalid={!!errors.json?.memoryGame?.cards?.borderRadius}
                        isValid={touched.json?.memoryGame?.cards?.borderRadius && !errors.json?.memoryGame?.cards?.borderRadius}
                    />
                    <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        {errors.json?.memoryGame?.cards?.borderRadius}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                    as={Col}
                    md={4}
                    className="mb-3"
                   >
                    <Form.Label>Margem (rem)</Form.Label>
                    <Form.Control
                        type="number"
                        name="json.memoryGame.cards.padding"
                        step={"0.01"}
                        value={values.json.memoryGame.cards.padding}
                        onChange={handleChange}
                        isInvalid={!!errors.json?.memoryGame?.cards?.padding}
                        isValid={touched.json?.memoryGame?.cards?.padding && !errors.json?.memoryGame?.cards?.padding}
                    />
                    <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        {errors.json?.memoryGame?.cards?.padding}
                    </Form.Control.Feedback>
                </Form.Group>
            </Row>
            <Row className="mx-0 mb-3">
                <Form.Group
                    as={Col}
                    md="6">
                    <Card className="mb-3">
                        <Card.Body>
                            <div className="text-center mb-3">
                                <div className="d-flex justify-content-center mb-3">
                                    <Form.Label>Frente das cartas</Form.Label>
                                </div>
                            </div>
                            <Form.Control
                                type="hidden"
                                name="json.memoryGame.cards.front"
                                value={values.json.memoryGame.cards.front}
                                onChange={handleChange}
                                isInvalid={!!errors?.json?.memoryGame?.cards?.front}
                                isValid={touched?.json?.memoryGame?.cards?.front && !errors?.json?.memoryGame?.cards?.front}
                            />
                            <div className="imagesUpload">
                                <img
                                    className={`h-100 img-thumbnail ${!!errors?.json?.memoryGame?.cards?.front ? "is-invalid" : ""}`}
                                    src={
                                        values.json.memoryGame?.cards?.front !== "" && values.json.memoryGame?.cards?.front !== null
                                            ? domainConfig.imageServer +
                                            "/customizacao/" +
                                            values.customizacao_id +
                                            "/" +
                                            values.json.memoryGame?.cards?.front
                                            : domainConfig.imageServer + "/empty-background.png"
                                    }
                                    alt="Imagem footer aplicativo"
                                />
                                <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">
                                    {errors?.json?.memoryGame?.cards?.front}
                                </Form.Control.Feedback>
                            </div>
                            <DropzoneComponent
                                className="mt-3 dropzoneComp"
                                type="image"
                                handlePreviewSuccess={handlePreviewSuccess}
                                handlePreviewError={handlePreviewError}
                                setFieldValue={setFieldValue}
                                folder="customizacao"
                                id={values.customizacao_id}
                                field="json.memoryGame.cards.front"
                            />
                        </Card.Body>
                    </Card>
                </Form.Group>
            </Row>
            <Form.Group as={Col} className="textHeaderAplicativo mb-3">
                <h5 className="card-title mb-0">Pares de cartas</h5>
            </Form.Group>
            <ButtonToolbar className="mb-4 justify-content-center">
                {pairs.map((element, index) => {
                    let hasPair = false
                    if (errors?.json?.memoryGame?.cards?.value) {
                        errors.json.memoryGame.cards.value.forEach((item, index2) => {
                            if (values.json.memoryGame.cards.value[index2] && item && item.url && element.pair === values.json.memoryGame.cards.value[index2].pair) {
                                hasPair = true
                            }
                        });
                    }
                    return (
                        <Button
                            key={"buttonQuestions" + index}
                            className={`mt-3 me-2 buttonToolbarApp ${hasPair ? 'is-invalid' : ''} ${currentPair === element.pair ? 'active' : ''}`}
                            onClick={() => setCurrentPair(element.pair)}
                        >
                            {index + 1}
                        </Button>
                    );
                })}
                <Button onClick={() => newPair()} className="mt-3 ms-2">
                    Adicionar
                </Button>
            </ButtonToolbar>
            <Row className="mx-0 mb-3">
                {values.json.memoryGame.cards.value.map((element, index) => {
                    if (element.pair !== currentPair) {
                        return ("")
                    }
                    return (
                        <Form.Group
                            as={Col}
                            md="6"
                            key={index + 'card'}>
                            <Card className="mb-3">
                                <Card.Body>
                                    <Form.Control
                                        type="hidden"
                                        name={`json.memoryGame.cards.value[${index}].url`}
                                        value={values.json.memoryGame.cards.value[index].url}
                                        onChange={handleChange}
                                        isInvalid={!!errors?.json?.memoryGame?.cards?.value[index]?.url}
                                        isValid={touched?.json?.memoryGame?.cards?.value[index]?.url && !errors?.json?.memoryGame?.cards?.value[index]?.url}
                                    />
                                    <div className="imagesUpload">
                                        <img
                                            className={`h-100 img-thumbnail ${!!errors?.json?.memoryGame?.cards?.value[index]?.url ? "is-invalid" : ""}`}
                                            src={
                                                values.json.memoryGame?.cards?.value[index]?.url !== "" && values.json.memoryGame?.cards?.value[index]?.url !== null
                                                    ? domainConfig.imageServer +
                                                    "/customizacao/" +
                                                    values.customizacao_id +
                                                    "/" +
                                                    values.json.memoryGame?.cards?.value[index]?.url
                                                    : domainConfig.imageServer + "/empty-background.png"
                                            }
                                            alt="Imagem footer aplicativo"
                                        />
                                        <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                                        <Form.Control.Feedback type="invalid">
                                            {errors?.json?.memoryGame?.cards?.value[index]?.url}
                                        </Form.Control.Feedback>
                                    </div>
                                    <DropzoneComponent
                                        className="mt-3 dropzoneComp"
                                        type="image"
                                        handlePreviewSuccess={handlePreviewSuccess}
                                        handlePreviewError={handlePreviewError}
                                        setFieldValue={setFieldValue}
                                        folder="customizacao"
                                        id={values.customizacao_id}
                                        field={`json.memoryGame.cards.value[${index}].url`}
                                    />
                                </Card.Body>
                            </Card >
                        </Form.Group >
                    );
                })}
                {values.json.memoryGame.cards.value.length > 2 && <Col className="text-center">
                    <Button
                        variant="outline-danger"
                        className="mb-3"
                        onClick={() => deletePair(currentPair)}
                    >
                        Excluir
                    </Button>
                </Col>}
            </Row >

            <Form.Group as={Col} className="textHeaderAplicativo mb-3">
                <h5 className="card-title mb-0">Participante</h5>
            </Form.Group>
            <Row className="mx-0 mb-3">
                <p className="text-start fw-bold mb-2">Tela de resultado</p>
                <Form.Group
                    className="mb-3"
                    as={Col}
                    sm="12"
                   
                >
                    <Form.Label>Título</Form.Label>
                    <Form.Control
                        as="textarea"
                        name="json.endText.title.value"
                        value={values.json.endText.title.value}
                        onChange={(e) => {
                            if (e.target.value === '') {
                                setFieldValue("json.endText.title.status", false);
                            } else {
                                setFieldValue("json.endText.title.status", true);
                            }
                            setFieldValue("json.endText.title.value", e.target.value);
                        }}
                        isInvalid={!!errors?.json?.endText?.title?.value}
                        isValid={touched?.json?.endText?.title?.value && !errors.json?.endText?.title?.value}
                    />
                    <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        {errors?.json?.endText?.title?.value}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                    className="mb-3"
                    as={Col}
                    sm="12"
                   
                >
                    <Form.Label>Descrição</Form.Label>
                    <Form.Control
                        as="textarea"
                        name="json.endText.description.description"
                        value={values.json.endText.description.value}
                        onChange={(e) => {
                            if (e.target.value === '') {
                                setFieldValue("json.endText.description.status", false);
                            } else {
                                setFieldValue("json.endText.description.status", true);
                            }
                            setFieldValue("json.endText.description.value", e.target.value);
                        }}
                        isInvalid={!!errors?.json?.endText?.description?.value}
                        isValid={touched?.json?.endText?.description?.value && !errors.json?.endText?.description?.value}
                    />
                    <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        {errors?.json?.endText?.description?.value}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                    className="mb-3"
                    as={Col}
                    sm="12"
                   
                >
                    <Form.Check
                        checked={values.json.showScore ? true : false}
                        type="checkbox"
                        name="json.showScore"
                        label='Mostrar pontuação'
                        onChange={(e) => {
                            setFieldValue(
                                "json.showScore",
                                values.json.showScore ? false : true
                            );
                        }}
                    />
                    <Form.Check
                        checked={values.json.retry.status === true ? true : false}
                        type="checkbox"
                        name="json.retry.status"
                        label="Opção de jogar novamente"
                        onChange={(e) => {
                            setFieldValue(
                                "json.retry.status",
                                values.json.retry.status === true ? false : true
                            );
                        }}
                    />
                    {values.json.retry.status === true ? (
                        <>
                            <Form.Group
                                className="my-2"
                                as={Col}
                                sm="6"
                               
                            >
                                <Form.Control
                                    name="json.retry.text"
                                    value={values.json.retry.text}
                                    onChange={handleChange}
                                    isInvalid={!!errors.json?.retry?.text}
                                    isValid={
                                        touched.json?.retry?.text &&
                                        !errors.json?.retry?.text
                                    }
                                />
                                <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">
                                    {errors.json?.retry?.text}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </>
                    ) : (
                        ""
                    )}
                </Form.Group>
            </Row>
            <Form.Group as={Col} className="textHeaderAplicativo mb-3">
                <h5 className="card-title mb-0">Modo apresentação</h5>
            </Form.Group>
            <Row className="mx-0 mb-3">
                <Form.Group
                    className="mb-3"
                    as={Col}
                    sm="12"
                   
                >
                    <Form.Label>Título</Form.Label>
                    <Form.Control
                        as="textarea"
                        name="json.apresentation.rankingPage.title.value"
                        value={values.json.apresentation?.rankingPage?.title?.value}
                        onChange={(e) => {
                            if (e.target.value === '') {
                                setFieldValue("json.apresentation.rankingPage.title.status", false);
                            } else {
                                setFieldValue("json.apresentation.rankingPage.title.status", true);
                            }
                            setFieldValue("json.apresentation.rankingPage.title.value", e.target.value);
                        }}
                        isInvalid={!!errors?.json?.apresentation?.rankingPage?.title?.value}
                        isValid={touched?.json?.apresentation?.rankingPage?.title?.value && !errors?.json?.apresentation?.rankingPage?.title?.value}
                    />
                    <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        {errors.json?.apresentation?.rankingPage?.title?.value}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group
                    as={Col}
                    md={4}
                    className="mb-3"
                   >
                    <Form.Label>Tamanho fonte (M) (rem)</Form.Label>
                    <Form.Control
                        type="number"
                        name="json.apresentation.textSide.h5"
                        step={"0.01"}
                        value={values.json.apresentation?.textSide?.h5}
                        onChange={handleChange}
                        isInvalid={!!errors.json?.apresentation?.textSide?.h5}
                        isValid={touched.json?.apresentation?.textSide?.h5 && !errors.json?.apresentation?.textSide?.h5}
                    />
                    <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        {errors.json?.apresentation?.textSide?.h5}
                    </Form.Control.Feedback>
                </Form.Group>

                <Form.Group
                    as={Col}
                    md={4}
                    className="mb-3"
                   >
                    <Form.Label>Tamanho fonte (P) (rem)</Form.Label>
                    <Form.Control
                        type="number"
                        name="json.apresentation.textSide.p"
                        step={"0.01"}
                        value={values.json.apresentation?.textSide?.p}
                        onChange={handleChange}
                        isInvalid={!!errors.json?.apresentation?.textSide?.p}
                        isValid={touched.json?.apresentation?.textSide?.p && !errors.json?.apresentation?.textSide?.p}
                    />
                    <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        {errors.json?.apresentation?.textSide?.p}
                    </Form.Control.Feedback>
                </Form.Group>
                <div className="col-md-4">
                    <Form.Label>Cor de fundo e texto</Form.Label>
                    <div className={`d-flex justify-content-start align-items-center ${errors?.json?.apresentation?.textSide?.textColor || errors?.json?.apresentation?.textSide?.backgroundColor ? 'is-invalid' : ''}`}>
                        <Form.Control
                            type="hidden"
                            name="json.apresentation.textSide.backgroundColor"
                            value={values.json?.apresentation?.textSide?.backgroundColor}
                            isInvalid={!!errors?.json?.apresentation?.textSide?.backgroundColor}
                            isValid={touched?.json?.apresentation?.textSide?.backgroundColor && !errors?.json?.apresentation?.textSide?.backgroundColor}
                        />
                        <Form.Control
                            type="hidden"
                            name="json.apresentation.textSide.textColor"
                            value={values.json?.apresentation?.textSide?.textColor}
                            isInvalid={!!errors?.json?.apresentation?.textSide?.textColor}
                            isValid={touched?.json?.apresentation?.textSide?.textColor && !errors?.json?.apresentation?.textSide?.textColor}
                        />
                        <PopoverPicker
                            color={props.backgroundColor}
                            onChangeX={(e) => {
                                setFieldValue("json.apresentation.textSide.backgroundColor", e);
                                setProps(prevState => ({
                                    ...prevState,
                                    backgroundColor: e
                                }));
                            }}
                            classes={`me-1 picker ${errors?.json?.apresentation?.textSide?.backgroundColor ? 'is-invalid' : ''}`}
                        />
                        <PopoverPicker
                            color={props.textColor}
                            onChangeX={(e) => {
                                setFieldValue("json.apresentation.textSide.textColor", e);
                                setProps(prevState => ({
                                    ...prevState,
                                    textColor: e
                                }));
                            }}
                            classes={`me-1 picker ${errors?.json?.apresentation?.textSide?.textColor ? 'is-invalid' : ''}`}
                        />
                    </div>
                    <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        {errors?.json?.apresentation?.textSide?.textColor ? errors?.json?.apresentation?.textSide?.textColor : errors?.json?.apresentation?.textSide?.backgroundColor ? errors?.json?.apresentation?.textSide?.backgroundColor : ''}
                    </Form.Control.Feedback>
                </div>
                <Form.Group
                    as={Col}
                    md="6"
                   
                >
                    <Form.Label>Tipo de layout</Form.Label>
                    <Form.Select
                        onChange={(e) => {
                            if (e.target.value == 2) {
                                setFieldValue(
                                    "json.apresentation.imageSide.value", 40
                                );
                                setFieldValue(
                                    "json.apresentation.textSide.value", 60
                                );
                            } else {
                                setFieldValue(
                                    "json.apresentation.imageSide.value", 0
                                );
                                setFieldValue(
                                    "json.apresentation.textSide.value", 100
                                );
                            }
                        }}
                        value={values.json.apresentation?.imageSide?.value && values.json.apresentation?.imageSide?.value > 0 ? 2 : 1}>
                        <option value={1}>
                            Simples
                        </option>
                        <option value={2}>
                            Com banner
                        </option>
                    </Form.Select>
                </Form.Group>
            </Row>
            {
                values.json.apresentation.imageSide?.value > 0 &&
                <>
                    <Row className="mx-0">
                        <Form.Group
                            as={Col}
                            md="3"
                            className="mb-3"
                           >
                            <Form.Label>Tamanho do banner (%)</Form.Label>
                            <Form.Control
                                type="number"
                                name="json.apresentation.imageSide.value"
                                value={values.json.apresentation?.imageSide?.value}
                                onChange={(e) => {
                                    if (e.target.value === undefined || e.target.value === '') {
                                        e.target.value = 0
                                    }
                                    let textSideValue = 100 - e.target.value
                                    setFieldValue("json.apresentation.imageSide.value", e.target.value);
                                    setFieldValue("json.apresentation.textSide.value", textSideValue);
                                }}
                                isInvalid={!!errors.json?.apresentation?.imageSide?.value}
                                isValid={touched.json?.apresentation?.imageSide?.value && !errors.json?.apresentation?.imageSide?.value}
                            />
                            <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid">
                                {errors.json?.apresentation?.imageSide?.value}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group
                            as={Col}
                            md="3"
                            className="mb-3"
                           
                        >
                            <Form.Label>Posição</Form.Label>
                            <Form.Select
                                onChange={handleChange}
                                name="json.apresentation.imageSide.position"
                                value={values.json.apresentation.imageSide.position}>
                                <option value={'start'}>
                                    Esquerda
                                </option>
                                <option value={'end'}>
                                    Direita
                                </option>
                            </Form.Select>
                        </Form.Group>
                    </Row>
                    <Row className="mx-0 mb-3">
                        <Form.Group
                            as={Col}
                            md="6">
                            <Card className="mb-3">
                                <Card.Body>
                                    <div className="text-center mb-3">
                                        <div className="d-flex justify-content-center mb-3">
                                            <Form.Label>Banner</Form.Label>
                                        </div>
                                    </div>
                                    <Form.Control
                                        type="hidden"
                                        name="json.apresentation.imageSide.background"
                                        value={values.json.apresentation?.imageSide?.background}
                                        onChange={handleChange}
                                        isInvalid={!!errors?.json?.apresentation?.imageSide?.background}
                                        isValid={touched?.json?.apresentation?.imageSide?.background && !errors?.json?.apresentation?.imageSide?.background}
                                    />
                                    <div className="imagesUpload">
                                        <img
                                            className={`h-100 img-thumbnail ${!!errors?.json?.apresentation?.imageSide?.background ? "is-invalid" : ""}`}
                                            src={
                                                values.json.apresentation?.imageSide?.background !== "" && values.json.apresentation?.imageSide?.background !== null
                                                    ? domainConfig.imageServer +
                                                    "/customizacao/" +
                                                    values.customizacao_id +
                                                    "/" +
                                                    values.json.apresentation?.imageSide?.background
                                                    : domainConfig.imageServer + "/empty-background.png"
                                            }
                                            alt="Imagem footer aplicativo"
                                        />
                                        <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                                        <Form.Control.Feedback type="invalid">
                                            {errors?.json?.apresentation?.imageSide?.background}
                                        </Form.Control.Feedback>
                                    </div>
                                    <DropzoneComponent
                                        className="mt-3 dropzoneComp"
                                        type="image"
                                        handlePreviewSuccess={handlePreviewSuccess}
                                        handlePreviewError={handlePreviewError}
                                        setFieldValue={setFieldValue}
                                        folder="customizacao"
                                        id={values.customizacao_id}
                                        field="json.apresentation.imageSide.background"
                                    />
                                </Card.Body>
                            </Card>
                        </Form.Group>
                    </Row>
                </>
            }
            <Row className="mx-0">
                <Form.Group
                    as={Col}
                    sm="12"
                   
                >
                    <Form.Check
                        checked={values.json.apresentation?.showQRCode?.status === true ? true : false}
                        type="checkbox"
                        name="json.apresentation.showQRCode.status"
                        label="Mostrar QRCode"
                        onChange={(e) => {
                            setFieldValue(
                                "json.apresentation.showQRCode.status",
                                values.json.apresentation?.showQRCode?.status === true ? false : true
                            );
                        }}
                    />
                    {values.json.apresentation?.showQRCode?.status === true ? (
                        <Row>
                            <Form.Group
                                className="my-2"
                                as={Col}
                                sm="4"
                               >
                                <Form.Label>Texto</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="json.apresentation.showQRCode.text"
                                    value={values.json.apresentation.showQRCode.text}
                                    onChange={handleChange}
                                    isInvalid={!!errors.apresentation?.showQRCode?.text}
                                    isValid={
                                        touched.json?.apresentation?.showQRCode?.text &&
                                        !errors.json?.apresentation?.showQRCode?.text
                                    }
                                />
                                <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">
                                    {errors.json?.apresentation?.showQRCode?.text}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group
                                className="my-2"
                                as={Col}
                                sm="4"
                               
                            >
                                <Form.Label>URL<OverlayTrigger overlay={<Tooltip>Caso nenhuma URL seja fornecida o QRCode irá redirecionar o usuário ao evento</Tooltip>}>
                                    <HelpTooltip icon={faExclamationCircle} />
                                </OverlayTrigger></Form.Label>
                                <Form.Control
                                    type="text"
                                    name="json.apresentation.showQRCode.link"
                                    value={values.json.apresentation.showQRCode.link}
                                    onChange={handleChange}
                                    isInvalid={!!errors.apresentation?.showQRCode?.link}
                                    isValid={
                                        touched.json?.apresentation?.showQRCode?.link &&
                                        !errors.json?.apresentation?.showQRCode?.link
                                    }
                                />
                                <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">
                                    {errors.json?.apresentation?.showQRCode?.link}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group
                                className="my-2"
                                as={Col}
                                sm="4"
                               
                            >
                                <Form.Label>Posição Y</Form.Label>
                                <Form.Select
                                    onChange={(e) => {
                                        setFieldValue("json.apresentation.showQRCode.position.y", e.target.value);
                                    }}
                                    value={values.json.apresentation.showQRCode.position.y}>
                                    <option value={'top'}>
                                        Topo
                                    </option>
                                    <option value={'bottom'}>
                                        Fundo
                                    </option>
                                </Form.Select>
                                <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">
                                    {errors.json?.apresentation?.showQRCode?.position?.y}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group
                                className="my-2"
                                as={Col}
                                sm="4"
                               
                            >
                                <Form.Label>Posição X</Form.Label>
                                <Form.Select
                                    onChange={(e) => {
                                        setFieldValue("json.apresentation.showQRCode.position.x", e.target.value);
                                    }}
                                    value={values.json.apresentation.showQRCode.position.x}>
                                    <option value={'left'}>
                                        Esquerda
                                    </option>
                                    <option value={'right'}>
                                        Direita
                                    </option>
                                </Form.Select>
                                <Form.Control.Feedback type="valid"></Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">
                                    {errors.json?.apresentation?.showQRCode?.position?.x}
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                    ) : (
                        ""
                    )}
                </Form.Group>
            </Row>
        </>
    );
};

export default MemoryGame